export const drugDrugInteractionTabs = [
  {
    Title: 'Minor',
    Border: '1px solid #bbedc3',
    Background: '#C7FFD0',
    HoverBackground: '#edfff0',
  },
  {
    Title: 'Moderate',
    Border: '1px solid #eaedbb',
    Background: '#FCFFC7',
    HoverBackground: '#feffed',
  },
  {
    Title: 'Major',
    Border: '1px solid #edbbbb',
    Background: 'rgba(255, 233, 191, 0.4)',
    HoverBackground: '#FFF2D9',
  },
  {
    Title: 'Severe',
    Border: '1px solid #edbbbb',
    Background: '#FFC7C7',
    HoverBackground: '#ffeded',
  },
];
