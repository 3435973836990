import styled, { CSSObject } from 'styled-components';

export const MedElementContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem 0rem 1rem;
  width: 712px;
`;

export const LastActionRow = styled.div`
  display: flex;
  margin-top: 1rem;
`;

export const FilterText = styled.div`
  color: #b5307d;
  font-family: Ubuntu;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-self: center;
  padding-left: 4px;
`;
export const MedOverlay = styled.div<{ highlight: string }>`
  position: absolute;
  top: 0px;
  left: 0px;
  margin-left: -21px;
  width: 105%;
  height: 100%;
  background-color: ${(props) => getBackgroundColor(props.highlight)};
  z-index: 10;
  &:hover {
    cursor: ${(props) => (props.highlight === '' ? 'default' : 'pointer')};
  }
`;
export const DaterPickerAlignment = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 7px;
  padding-bottom: 16px;
`;
export const FlexContainer = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 5px;
  }
`;
const getBackgroundColor = (
  highlight: string
): CSSObject['backgroundColor'] => {
  switch (highlight) {
    case 'red':
      return 'rgba(255, 111, 111, 0.2)';
    case 'yellow':
      return 'rgba(250, 255, 19, 0.2)';
    case 'green':
      return 'rgba(73, 255, 91, 0.2)';
    default:
      return 'transparent';
  }
};

export const AdrElementContainer = styled.div`
  display: flex;
  width: 367px;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
`;

export const AdrDetails = styled.div<{
  background: string;
  border?: string;
  hoverBackground?: string;
}>`
  display: flex;
  padding: 8px;
  align-items: flex-start;
  border: ${(props) => props.border};
  background: ${(props) => props.background};
  /* Use margin-right to create the spacing between child elements */
  > *:not(:last-child) {
    margin-right: 22px;
  }
  cursor: pointer;

  &:hover {
    border-color: ${(props) => props.border};
    background: ${(props) => props.hoverBackground};
  }
`;

export const CheckBox = styled.div`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
`;

export const ApplyFilterButton = styled.div`
  background: #fff;
  text-align: center;
  border: 1px solid #b5297d;
  border-radius: 4px;
  width: 90px;
  height: 40px;
  padding-top: 7px;
  align-self: center;
  color: #b5307d;
  &:hover {
    color: white;
    background: #b5307d;
  }
`;
export const AdrNameAndSeverity = styled.div`
  color: #525252;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const TabContainer = styled.div<{
  background?: string;
  border?: string;
}>`
  display: flex;
  width: 100%;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${(props) => props.border};
  background: ${(props) => props.background};
`;

export const TabHeading = styled.div`
  color: #525252;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
