import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md';
import { isNull } from 'lodash';
import theme from '../global/theme/theme';

/**
 * Util function to get display properties of quantitative lab results
 * @param {float} value - Lab result value
 * @param {float} lowerLimit - lower limit
 * @param {float} upperLimit - upper limit
 * @returns {object} - display properties of quantitative results
 */
export const getQuantitativeLabDisplayProps = (
  value,
  lowerLimit,
  upperLimit
) => {
  const normalProps = {
    color: theme.blue,
    alignment: 'center',
    icon: <></>,
  };
  const aboveNormalProps = {
    color: theme.red,
    alignment: 'flex-start',
    icon: <MdOutlineArrowDropUp color={theme.red} />,
  };
  const belowNormalProps = {
    color: theme.red,
    alignment: 'flex-end',
    icon: <MdOutlineArrowDropDown color={theme.red} />,
  };
  const noUpperAndLowerLimitProps = {
    color: theme.grey,
    alignment: 'center',
    icon: <></>,
  };

  // CASE 1: if both upper and lower limits are not present
  if (isNull(lowerLimit) && isNull(upperLimit))
    return noUpperAndLowerLimitProps;
  else {
    // CASE 2: if both upper and lower limits are present
    // -> above normal if value greater than upper limit
    // -> below normal if value greater than lower limit

    // CASE 3: if upper limit is present and lower limit is not present
    // -> above normal if value greater than  upper limit

    // CASE 4: if upper limit is not present and lower limit is present
    // -> below normal if value lesser than lower limit
    if (!isNull(lowerLimit) && value < lowerLimit) return belowNormalProps;
    if (!isNull(upperLimit) && value > upperLimit) return aboveNormalProps;
  }

  return normalProps;
};
