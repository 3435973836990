import 'bootstrap/dist/css/bootstrap.min.css';
import '../tab/tab.css';

import {
  AdrDetails,
  AdrNameAndSeverity,
  CheckBox,
  TabContainer,
  TabHeading,
} from './by-type.style';
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md';
import {
  ModalAdrInfo,
  ModalCard,
  ModalCardMain,
} from '../modal/monograph-modal.style';
import React, { useState } from 'react';

import { AdrData } from '../../../types/smart-adr.types';
import checkBox from '../constants/svg/checkBox.svg';
import redCheckBox from '../constants/svg/redCheckBox.svg';

const ContraindicationByTpe: React.FC<any> = ({ contraindicationData }) => {
  const [selectedAdr, setSelectedAdr] = useState<AdrData>({
    id: '',
    adrName: '',
  });
  const [activeTab, setActiveTab] = useState<string>('');

  return (
    <>
      <TabContainer
        style={{
          pointerEvents:
            contraindicationData.blackBoxWarning.length === 0 ? 'none' : 'auto',
        }}
        background={
          contraindicationData.blackBoxWarning.length > 0 ? '#FFEDED' : ''
        }
        border='black'
        onClick={() => {
          if (activeTab === 'Black Box Warning') {
            setActiveTab('');
          } else {
            setActiveTab('Black Box Warning');
          }
          setSelectedAdr({
            id: '',
            adrName: '',
          });
        }}
      >
        <TabHeading>
          {' '}
          Black Box Warning (
          <span
            style={{
              color:
                contraindicationData.blackBoxWarning.length > 0 ? 'red' : '',
            }}
          >
            {contraindicationData.blackBoxWarning.length}
          </span>
          )
        </TabHeading>
        {activeTab === 'Black Box Warning' ? (
          <MdOutlineArrowDropUp color={'black'} size={20} />
        ) : (
          <MdOutlineArrowDropDown color={'black'} size={20} />
        )}
      </TabContainer>

      {activeTab === 'Black Box Warning' &&
        contraindicationData.blackBoxWarning.map(
          (adrDetail: any, index: any) => (
            <div>
              <div
                onClick={() => {
                  if (selectedAdr.id === index) {
                    setSelectedAdr({
                      id: '',
                      adrName: '',
                    });
                  } else {
                    setSelectedAdr({
                      id: index,
                      adrName: Object.keys(adrDetail)[0],
                    });
                  }
                }}
              >
                <AdrDetails
                  border={'1px solid #FFEDED'}
                  hoverBackground={
                    selectedAdr.id === index ? '#FFC7C7' : '#ffeded'
                  }
                  background={selectedAdr.id === index ? '#FFC7C7' : ' #ffffff'}
                >
                  <CheckBox>
                    {selectedAdr.id === index ? (
                      <img
                        style={{ marginBottom: '6px' }}
                        src={redCheckBox}
                        alt=''
                      />
                    ) : (
                      <img
                        style={{ marginBottom: '6px' }}
                        src={checkBox}
                        alt=''
                      />
                    )}
                  </CheckBox>
                  <AdrNameAndSeverity>
                    {adrDetail.medicationName}
                  </AdrNameAndSeverity>
                </AdrDetails>
              </div>
              {activeTab === 'Black Box Warning' && selectedAdr.id !== '' && (
                <ModalCard highlight={'#ffeded'} style={{ width: '100%' }}>
                  <ModalCardMain style={{ width: '100%' }}>
                    <ModalAdrInfo style={{ padding: '1rem 0rem' }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            contraindicationData['blackBoxWarning'][
                              selectedAdr.id
                            ].monographContent,
                        }}
                      />
                    </ModalAdrInfo>
                  </ModalCardMain>
                </ModalCard>
              )}
            </div>
          )
        )}
      {/* {activeTab === 'Black Box Warning' && selectedAdr.id !== '' && (
        <ModalCard highlight={'#ffeded'} style={{ width: '100%' }}>
          <ModalCardMain style={{ width: '100%' }}>
            <ModalAdrInfo style={{ padding: '1rem 0rem' }}>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    contraindicationData['blackBoxWarning'][selectedAdr.id]
                      .monographContent,
                }}
              />
            </ModalAdrInfo>
          </ModalCardMain>
        </ModalCard>
      )} */}
      <TabContainer
        style={{
          pointerEvents:
            contraindicationData.contraindication.length === 0
              ? 'none'
              : 'auto',
        }}
        background={
          contraindicationData.contraindication.length > 0 ? '#FFEDED' : ''
        }
        border='black'
        onClick={() => {
          if (activeTab === 'Absolute Contraindication') {
            setActiveTab('');
          } else {
            setActiveTab('Absolute Contraindication');
          }
          setSelectedAdr({
            id: '',
            adrName: '',
          });
        }}
      >
        <TabHeading>
          {' '}
          Absolute Contraindication (
          <span
            style={{
              color:
                contraindicationData.contraindication.length > 0 ? 'red' : '',
            }}
          >
            {contraindicationData.contraindication.length}
          </span>
          )
        </TabHeading>
        {activeTab === 'Absolute Contraindication' ? (
          <MdOutlineArrowDropUp color={'black'} size={20} />
        ) : (
          <MdOutlineArrowDropDown color={'black'} size={20} />
        )}
      </TabContainer>
      {activeTab === 'Absolute Contraindication' &&
        contraindicationData.contraindication.map(
          (adrDetail: any, index: any) => (
            <div>
              <div
                onClick={() => {
                  if (selectedAdr.id === index) {
                    setSelectedAdr({
                      id: '',
                      adrName: '',
                    });
                  } else {
                    setSelectedAdr({
                      id: index,
                      adrName: Object.keys(adrDetail)[0],
                    });
                  }
                }}
              >
                <AdrDetails
                  border={'1px solid #FFEDED'}
                  hoverBackground={
                    selectedAdr.id === index ? '#FFC7C7' : '#ffeded'
                  }
                  background={selectedAdr.id === index ? '#FFC7C7' : ' #ffffff'}
                >
                  <CheckBox>
                    {selectedAdr.id === index ? (
                      <img
                        style={{ marginBottom: '6px' }}
                        src={redCheckBox}
                        alt=''
                      />
                    ) : (
                      <img
                        style={{ marginBottom: '6px' }}
                        src={checkBox}
                        alt=''
                      />
                    )}
                  </CheckBox>
                  <AdrNameAndSeverity>
                    {adrDetail.medicationName}
                  </AdrNameAndSeverity>
                </AdrDetails>
              </div>
              {activeTab === 'Absolute Contraindication' &&
                selectedAdr.id !== '' &&
                selectedAdr.id === index && (
                  <ModalCard highlight={'#ffeded'} style={{ width: '100%' }}>
                    <ModalCardMain style={{ width: '100%' }}>
                      <ModalAdrInfo style={{ padding: '1rem 0rem' }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              contraindicationData['contraindication'][
                                selectedAdr.id
                              ].monographContent,
                          }}
                        />
                      </ModalAdrInfo>
                    </ModalCardMain>
                  </ModalCard>
                )}
            </div>
          )
        )}
      {/* {activeTab === 'Absolute Contraindication' && selectedAdr.id !== '' && (
        <ModalCard highlight={'#ffeded'} style={{ width: '100%' }}>
          <ModalCardMain style={{ width: '100%' }}>
            <ModalAdrInfo style={{ padding: '1rem 0rem' }}>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    contraindicationData['contraindication'][selectedAdr.id]
                      .monographContent,
                }}
              />
            </ModalAdrInfo>
          </ModalCardMain>
        </ModalCard>
      )} */}
      <TabContainer
        style={{
          pointerEvents:
            contraindicationData.beersCriteria.length === 0 ? 'none' : 'auto',
        }}
        background={
          contraindicationData.beersCriteria.length > 0 ? '#FFEDED' : ''
        }
        border='black'
        onClick={() => {
          if (activeTab === 'Beers Criteria') {
            setActiveTab('');
          } else {
            setActiveTab('Beers Criteria');
          }
          setSelectedAdr({
            id: '',
            adrName: '',
          });
        }}
      >
        <TabHeading>
          {' '}
          Beers Criteria (
          <span
            style={{
              color: contraindicationData.beersCriteria.length > 0 ? 'red' : '',
            }}
          >
            {contraindicationData.beersCriteria.length}
          </span>
          )
        </TabHeading>
        {activeTab === 'Beers Criteria' ? (
          <MdOutlineArrowDropUp color={'black'} size={20} />
        ) : (
          <MdOutlineArrowDropDown color={'black'} size={20} />
        )}
      </TabContainer>
      {activeTab === 'Beers Criteria' &&
        contraindicationData.beersCriteria.map((adrDetail: any, index: any) => (
          <div
          // onClick={() => {
          //   setSelectedAdr({
          //     id: index,
          //     adrName: Object.keys(adrDetail)[0],
          //   });
          // }}
          >
            <AdrDetails
              border={'1px solid #FFEDED'}
              hoverBackground={''}
              background={''}
            >
              {/* <CheckBox>
                {selectedAdr.id === index ? (
                  <img
                    style={{ marginBottom: '6px' }}
                    src={redCheckBox}
                    alt=''
                  />
                ) : (
                  <img style={{ marginBottom: '6px' }} src={checkBox} alt='' />
                )}
              </CheckBox> */}
              <AdrNameAndSeverity>
                {adrDetail.medicationName}
              </AdrNameAndSeverity>
            </AdrDetails>
          </div>
        ))}

      {/* {activeTab === 'Beers Criteria' && selectedAdr.id !== '' && (
        <ModalCard highlight={'#ffeded'} style={{ width: '100%' }}>
          <ModalCardMain style={{ width: '100%' }}>
            <ModalAdrInfo style={{ padding: '1rem 0rem' }}>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    contraindicationData['beersCriteria'][selectedAdr.id]
                      .monographContent,
                }}
              />
            </ModalAdrInfo>
          </ModalCardMain>
        </ModalCard>
      )} */}

      <TabContainer
        style={{
          pointerEvents:
            contraindicationData.duplicatetherapy.length === 0
              ? 'none'
              : 'auto',
        }}
        background={
          contraindicationData.duplicatetherapy.length > 0 ? '#FFEDED' : ''
        }
        border='black'
        onClick={() => {
          if (activeTab === 'Duplicate Therapy') {
            setActiveTab('');
          } else {
            setActiveTab('Duplicate Therapy');
          }
          setSelectedAdr({
            id: '',
            adrName: '',
          });
        }}
      >
        <TabHeading>
          {' '}
          Duplicate Therapy (
          <span
            style={{
              color:
                contraindicationData.duplicatetherapy.length > 0 ? 'red' : '',
            }}
          >
            {contraindicationData.duplicatetherapy.length}
          </span>
          )
        </TabHeading>
        {activeTab === 'Duplicate Therapy' ? (
          <MdOutlineArrowDropUp color={'black'} size={20} />
        ) : (
          <MdOutlineArrowDropDown color={'black'} size={20} />
        )}
      </TabContainer>
      {activeTab === 'Duplicate Therapy' &&
        contraindicationData.duplicatetherapy.map(
          (adrDetail: any, index: any) => (
            <div
            // onClick={() => {
            //   setSelectedAdr({
            //     id: index,
            //     adrName: Object.keys(adrDetail)[0],
            //   });
            // }}
            >
              <AdrDetails
                border={'1px solid #FFEDED'}
                hoverBackground={''}
                background={''}
              >
                {/* <CheckBox>
                  {selectedAdr.id === index ? (
                    <img
                      style={{ marginBottom: '6px' }}
                      src={redCheckBox}
                      alt=''
                    />
                  ) : (
                    <img
                      style={{ marginBottom: '6px' }}
                      src={checkBox}
                      alt=''
                    />
                  )}
                </CheckBox> */}
                <AdrNameAndSeverity>
                  {adrDetail.medicationName}
                </AdrNameAndSeverity>
              </AdrDetails>
            </div>
          )
        )}
      {/* {activeTab === 'Duplicate Therapy' && selectedAdr.id !== '' && (
        <ModalCard highlight={'#ffeded'} style={{ width: '100%' }}>
          <ModalCardMain style={{ width: '100%' }}>
            <ModalAdrInfo style={{ padding: '1rem 0rem' }}>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    contraindicationData['duplicatetherapy'][selectedAdr.id]
                      .monographContent,
                }}
              />
            </ModalAdrInfo>
          </ModalCardMain>
        </ModalCard>
      )} */}
      <TabContainer
        style={{
          pointerEvents: 'none',
        }}
        border='black'
      >
        <TabHeading> Pregnancy/Lactation (0)</TabHeading>
        {activeTab === 'Duplicate Therapy' ? (
          <MdOutlineArrowDropUp color={'black'} size={20} />
        ) : (
          <MdOutlineArrowDropDown color={'black'} size={20} />
        )}
      </TabContainer>
    </>
  );
};

export default ContraindicationByTpe;
