import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background-color: #00000099;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

export const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const ModalCard = styled.div<{ highlight: string }>`
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 420px;
  min-width: 360px;
  max-width: 695px;
  background-color: ${(props) => props.highlight};
  border: 1px solid ${(props) => props.theme.white};
  border-radius: 4px;
  padding: 0rem 1rem;
  z-index: 200;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 1.5rem;
  top: 2rem;
  &:hover {
    cursor: pointer;
  }
`;

export const ModalCardMain = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem;
`;

export const ModalTabContainer = styled.div`
  display: flex;
  border: 1px solid rgba(181, 41, 125, 0.043);
  border-radius: 4px;
  overflow: hidden;
  background-color: rgba(181, 41, 125, 0.043);

  overflow-x: auto;
`;

export const ModalAdrInfo = styled.div`
  display: flex;
  padding: 2rem 1rem;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: auto;
`;

export const ModalTab = styled.div<{ active: boolean; color: string }>`
  display: flex;
  align-items: center;
  padding: 0.5rem 1.25rem;
  border-bottom: 4px solid
    ${(props) => (props.active ? props.color : '#00000000')};
  background-color: ${(props) =>
    props.active ? getModalTabBackgroundColor(props.color) : '#00000000'};
  &:hover {
    cursor: pointer;
  }
`;

const getModalTabBackgroundColor = (highlight: string) => {
  switch (highlight) {
    case '#D13C3C':
      return 'rgba(209, 60, 60, 0.1333)';
    case '#9A8D17':
      return 'rgba(154, 141, 23, 0.1333)';
    case '#505701':
      return 'rgba(80, 87, 1, 0.1333)';
    default:
      return 'transparent';
  }
};
