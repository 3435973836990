const theme = {
  highlight: {
    red: '#FF6F6F33',
    yellow: '#FAFF1333',
    green: '#49FF5B32',
  },
  text: {
    primary: '#343434',
    secondary: '#525252',
    tertiary: '#666666',
    light: '#9C9C9C',
    blue: '#007CBA',
  },
  black: '#000000',
  red: '#D13C3C',
  blue: '#2C79EE',
  white: '#FFFFFF',
  primary: '#B5297D',
  purple: '#5449D8',
  orange: '#D76526',
  mudGreen: '#505701',
  dashboardBG: '#E5F4FF',
  dashboardBGMain: '#F5FDFF',
  lightGrey: '#E9E9E9',
  grey: '#808080',
  ioTeal: '#A1D4DF',
  ioYellow: '#ECD400',
  vitalsGreen: '#247C5C',
  lightGreen: '#EDFFF0',
  modal: {
    highlightRed: '#FFF4F4',
    highlightYellow: '#FFFDEA',
    deepYellow: '#9A8D17',
  },
};

export default theme;
