import styled from 'styled-components';

export const TabContainer = styled.div`
  display: flex;
  padding-top: 10px;
  width: 100%;
`;

export const Tab = styled.div<{ backgroundColor?: string }>`
  display: flex;
  position: relative;
  padding: 10px 24px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#fff'};
  cursor: pointer;
  border: 1px solid #ccc;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  transition: background-color 0.3s;
  width: 50%;
  justify-content: center;

  &.active {
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : '#fff'};
    border-top: 5px solid ${(props) => (props.color ? props.color : '#000')};
    font-weight: 700;
    color: ${(props) => (props.color ? props.color : '#000')};
    &::before {
      opacity: 1;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 10%;
    height: 100%;
    background-color: purple;
    z-index: -1;
    border-radius: 5px 0 0 0;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .datepicker-icon {
    width: 40px;
    height: 31px;
    margin-left: 65%;
    padding: 3px;
    /* You can add more styles as needed */
    &:hover {
      background: #faf0f5;
    }

    .datepicker-icon:focus {
      outline: 2px solid blue; /* Replace 'blue' with the desired color for the outline */
    }
  }
`;

export const TabContent = styled.div`
  padding: 6.4px;
  background-color: #fff;
  width: 100%;
`;
