import {
  addQueryParamsToUrl,
  generateUrl,
  updatePathParamsForApiUrl,
} from '../utils/api.utils';

import HttpConstants from '../constants/http.constants';
import { SessionStorageConstants } from '../constants/session-storage.constants';
import UrlConstants from '../constants/url.constants';
import axios from 'axios';

/**
 * Function to make authenticated api call
 * @param {string} usecase
 * @param {HttpConstants} httpMethod
 * @param {*} data
 */
export const makeAuthenticatedApiCall = (
  usecase: string,
  httpMethod: string,
  data: any
) => {
  try {
    return makeApiCall(usecase, httpMethod, data, true);
  } catch (error) {
    throw error;
  }
};

/**
 * Function to make api call
 * @param {string} usecase
 * @param {HttpConstants} httpMethod
 * @param {*} data
 * @param {boolean} authenticatedCall
 */
export const makeApiCall = (
  usecase: string,
  httpMethod: string,
  data: any,
  authenticatedCall = false
) => {
  try {
    let url = generateUrl(usecase);
    const headers = {
      headers: {},
    };

    if (usecase === 'GET_MEDICATION_DETAILS_FROM_EPIC') {
      url = updatePathParamsForApiUrl(url, data).url;
    }

    // add header
    if (authenticatedCall) {
      if (UrlConstants[usecase]['SERVER'] === 'SMART_ADR_BACKEND') {
        headers.headers = {
          'x-api-key': process.env.REACT_APP_BACKEND_API_KEY,
        };
      }
      if (
        UrlConstants[usecase]['USECASE'] === 'GET_MED_CHECK_ADR_DETAILS' ||
        UrlConstants[usecase]['USECASE'] === 'GET_PATIENT_LAB'
      ) {
        const metaDataEndpoints = sessionStorage.getItem(
          SessionStorageConstants.EPIC_METADATA
        );
        const parsedMetaDataEndpoints = metaDataEndpoints
          ? JSON.parse(metaDataEndpoints)
          : null;
        headers.headers = {
          Authorization: sessionStorage.getItem(
            SessionStorageConstants.EPIC_ACCESS_TOKEN
          ),
          'patient-id': sessionStorage.getItem(
            SessionStorageConstants.EPIC_PATIENT_ID
          ),
          audience: parsedMetaDataEndpoints['audience'],
        };
      }
    }

    switch (httpMethod) {
      case HttpConstants.GET:
        if (data) url = addQueryParamsToUrl(url, data, httpMethod);
        return axios.get(url, headers);
      case HttpConstants.POST:
        if (usecase === 'GET_ADR') {
          url = addQueryParamsToUrl(url, data[1], httpMethod, usecase);
          return axios.post(url, data[0], headers);
        }
        return axios.post(url, data, headers);
      case HttpConstants.PATCH:
        return axios.patch(url, data, headers);
      default:
        throw new Error('Invalid HTTP method');
    }
  } catch (error) {
    throw error;
  }
};
