import { HorizontalLineProps } from '../../types/styles.types';
import React from 'react';
import styled from 'styled-components';

const HorizontalLineElement = styled.hr<HorizontalLineProps>`
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  opacity: ${(props) => props.opacity};
`;

const HorizontalLine: React.FC<HorizontalLineProps> = ({
  width = '100%',
  margin = '0',
  opacity = '0.75',
}) => {
  return (
    <HorizontalLineElement width={width} margin={margin} opacity={opacity} />
  );
};

export default HorizontalLine;
