import {
  LabReadingContainer,
  RangeBubble,
  RangeContainer,
  ReadingValueContainer,
} from './labs-reading.styled';

import React from 'react';
import TextSpan from '../../atoms/text-span';
import { getQuantitativeLabDisplayProps } from '../../../utils/helper.utils';

const LabReading = ({ name, value, rangeLow, rangeHigh, effectiveDate }) => {
  const labReadingProps = getQuantitativeLabDisplayProps(
    value,
    rangeLow,
    rangeHigh
  );

  return (
    <LabReadingContainer>
      <TextSpan color='tertiary' padding='0 0 0.25rem 0.5rem' weight='600'>
        {name}
      </TextSpan>
      <ReadingValueContainer>
        <RangeContainer>
          <RangeBubble
            color={labReadingProps.color}
            alignment={labReadingProps.alignment}
          ></RangeBubble>
        </RangeContainer>
        <TextSpan
          padding='0 0.5rem'
          overwriteColor={labReadingProps.color}
          weight='600'
        >
          {value}
        </TextSpan>
        {labReadingProps.icon}
      </ReadingValueContainer>
      <TextSpan color='light' padding='0.25rem 0 0 0.5rem'>
        {effectiveDate}
      </TextSpan>
    </LabReadingContainer>
  );
};

export default LabReading;
