import {
  Accordion,
  AccordionContent,
  AccordionIconAlignment,
  AccordionSection,
} from '../../accordion/accordion.style';
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md';
import {
  AdrDetails,
  AdrNameAndSeverity,
  CheckBox,
  FlexContainer,
  MedElementContainer,
  MedOverlay,
} from './med-element.style';
import React, { useState } from 'react';
import '../tab/tab.css';
import rareSvg from '../constants/svg/rare.svg';
import infrequentSvg from '../constants/svg/infrequent.svg';
import commonSvg from '../constants/svg/common.svg';
import unknownSvg from '../constants/svg/unknown.svg'
import HorizontalLine from '../../atoms/horizontal-line';
import {
  SmartADRMedElementProps,
  AdrData,
} from '../../../types/smart-adr.types';
import SmartADRModal from '../../monograph-demo/modal/monograph-modal';
import TextSpan from '../../atoms/text-span';
import { Tab, Tabs } from 'react-bootstrap';
import { adrFrequencyTabs } from '../constants/smart-adr.constants';
import greenCheckBox from '../constants/svg/greenCheckBox.svg';
import yellowCheckBox from '../constants/svg//yellowCheckBox.svg';
import redCheckBox from '../constants/svg/redCheckBox.svg';
import checkBox from '../constants/svg/checkBox.svg';
import { isEmpty } from 'lodash';

const SmartADRMedElement: React.FC<SmartADRMedElementProps> = ({
  name,
  dosage,
  adrDetails,
  highlight,
  monographReference,
}) => {
  const [activeTab, setActiveTab] = useState<string>('SmartADR');
  const [selectedAdr, setSelectedAdr] = useState<AdrData>({
    id: '',
    adrName: '',
  });
  const [adrObject, setAdrObject] = useState<any[] | null>(null);
  const [selctedAdrFrequency, setSelectedAdrFrequency] = useState<string>('');
  const TabTitle = ({ title, key }: { title: string; key: any }) => {
    let adrLength = 0;
    if (title === 'Rare' && adrDetails.rare) {
      adrLength = adrDetails.rare.length;
    }
    if (title === 'Infrequent' && adrDetails.infrequent) {
      adrLength = adrDetails.infrequent.length;
    }
    if (title === 'Common' && adrDetails.common) {
      adrLength = adrDetails.common.length;
    }
    if (title === 'Unknown' && adrDetails.unknown) {
      adrLength = adrDetails.unknown.length;
    }
    return (
      <FlexContainer>
        <img
          src={
            title === 'Rare'
              ? rareSvg
              : title === 'Infrequent'
              ? infrequentSvg
              :title === 'Common'
              ? commonSvg
              :unknownSvg
          }
          alt=''
        />

        <span>{adrLength}</span>
        <span>{title}</span>
        {(activeTab === '1' && title === 'Rare') ||
        (activeTab === '2' && title === 'Infrequent') ||
        (activeTab === '3' && title === 'Common') ||
        (activeTab === '4' && title === 'Unknown')
        ? (
          <MdOutlineArrowDropUp color={'black'} size={20} />
        ) : (
          <MdOutlineArrowDropDown color={'black'} size={20} />
        )}
      </FlexContainer>
    );
  };

  const handleTabSelect = (key: any) => {
    if (activeTab === key) {
      setSelectedAdr({
        id: '',
        adrName: '',
      });
      setActiveTab('smart adr');
    } else {
      if (key === '1') {
        setAdrObject(adrDetails.rare);
        setSelectedAdrFrequency('rare');
      }
      if (key === '2') {
        setAdrObject(adrDetails.infrequent);
        setSelectedAdrFrequency('infrequent');
      }
      if (key === '3') {
        setAdrObject(adrDetails.common);
        setSelectedAdrFrequency('common');
      }
      if (key === '4') {
        setAdrObject(adrDetails.unknown);
        setSelectedAdrFrequency('unknown');
      }
      setActiveTab(key);
      setSelectedAdr({
        id: '',
        adrName: '',
      });
    }
  };

  return (
    <AccordionSection>
      <Accordion>
        <MedElementContainer>
          <TextSpan weight='bold'>{name}</TextSpan>
          <AccordionIconAlignment>
            <TextSpan color='secondary' padding='0.5rem 0rem' weight='bold'>
              {dosage}
            </TextSpan>
          </AccordionIconAlignment>
          {!isEmpty(adrDetails) && (
            <div
              style={{
                background: `linear-gradient(to right, ${
                  highlight === 'red'
                    ? 'rgba(255, 237, 237, 1)'
                    : highlight === 'yellow'
                    ? 'rgba(254, 255, 237, 1)'
                    : highlight === 'green'
                    ? 'rgba(237, 255, 240, 1)'
                    : ''
                } 95%, transparent 50%)`,
                zIndex: '100',
                width: '80%',
              }}
            >
              <Tabs
                activeKey={activeTab}
                onSelect={handleTabSelect}
                id='uncontrolled-tab-example'
                color={highlight}
              >
                <Tab eventKey='0' title=' ADR :' disabled></Tab>
                {adrFrequencyTabs.map((frequency: any, indexTab: any) => (
                  <Tab
                    className='custom-scrollbar'
                    eventKey={indexTab + 1}
                    title={
                      <TabTitle title={frequency.Title} key={indexTab + 1} />
                    }
                    disabled={
                      indexTab === 0
                        ? adrDetails.rare
                          ? false
                          : true
                        : indexTab === 1
                        ? adrDetails.infrequent
                          ? false
                          : true
                        : indexTab === 2
                        ? adrDetails.common
                          ? false
                          : true
                        : indexTab === 3
                        ? adrDetails.unknown
                          ? false
                          : true
                        : false // Provide a boolean value when none of the conditions match
                    }
                  >
                    {adrObject &&
                      adrObject.map((adrDetail: any, index: any) => (
                        <div
                          onClick={() => {
                            setSelectedAdr({
                              id: index,
                              adrName: Object.keys(adrDetail)[0],
                            });
                          }}
                        >
                          <AdrDetails
                            border={
                              highlight === 'red'
                                ? '1px solid #FFEDED'
                                : highlight === 'yellow'
                                ? '1px solid #FEFFED'
                                : highlight === 'green'
                                ? '1px solid #EDFFF0'
                                : ''
                            }
                            hoverBackground={
                              selectedAdr.adrName === Object.keys(adrDetail)[0]
                                ? frequency.Background
                                : frequency.HoverBackground
                            }
                            background={
                              selectedAdr.adrName === Object.keys(adrDetail)[0]
                                ? frequency.Background
                                : ' #ffffff'
                            }
                          >
                            <CheckBox>
                              {selectedAdr.adrName ===
                              Object.keys(adrDetail)[0] ? (
                                <img
                                  style={{ marginBottom: '6px' }}
                                  src={
                                    activeTab === '1'
                                      ? greenCheckBox
                                      : activeTab === '2'
                                      ? yellowCheckBox
                                      : redCheckBox
                                  }
                                  alt=''
                                />
                              ) : (
                                <img
                                  style={{ marginBottom: '6px' }}
                                  src={checkBox}
                                  alt=''
                                />
                              )}
                            </CheckBox>
                            <AdrNameAndSeverity>
                              {Object.keys(adrDetail)[0]}
                            </AdrNameAndSeverity>
                            <AdrNameAndSeverity>
                              {adrDetail[Object.keys(adrDetail)[0]][0].severity}
                            </AdrNameAndSeverity>
                          </AdrDetails>
                        </div>
                      ))}
                  </Tab>
                ))}
              </Tabs>
            </div>
          )}

          <HorizontalLine
            width='100%'
            opacity='0.25'
            margin='0.5rem 0rem 0rem 0rem'
          />
          {highlight && <MedOverlay highlight={highlight}></MedOverlay>}
          {selectedAdr.adrName && (
            <AccordionContent onClick={(event) => event.stopPropagation()}>
              <SmartADRModal
                adrDetails={
                  adrDetails[selctedAdrFrequency][selectedAdr.id][
                    selectedAdr.adrName
                  ]
                }
                monographReference={monographReference}
              />

              <HorizontalLine
                width='100%'
                opacity='0.35'
                margin='0.5rem 0rem 0rem 0rem'
              />
            </AccordionContent>
          )}
        </MedElementContainer>
      </Accordion>
    </AccordionSection>
  );
};

export default SmartADRMedElement;
