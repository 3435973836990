import HorizontalLine from '../atoms/horizontal-line';
import { ImDiamonds } from 'react-icons/im';
import { IoBandageSharp } from 'react-icons/io5';
import ReadingsCard from '../atoms/readings-card/readings-card';
import TextSpan from '../atoms/text-span';
import { patientdata } from '../../utils/patients-data/patient-data';
import styled from 'styled-components';
import theme from '../../global/theme/theme';
import { useParams } from 'react-router-dom';

const ProblemsListElement = styled.div`
  padding: 0.75rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  border-bottom: solid 1px ${(props) => props.theme.black + '30'};
`;

const ProblemName = styled.div`
  display: flex;
  align-items: center;
`;

const MedicalProblems = () => {
  const { id } = useParams();

  return (
    <ReadingsCard
      color={theme.primary}
      title='Medical Problems'
      icon={<IoBandageSharp />}
      id='medical-problems'
    >
      <TextSpan
        overwriteColor={theme.primary}
        padding='1rem 0 0.25rem 0.5rem'
        weight='900'
      >
        Problem List
      </TextSpan>
      <HorizontalLine opacity='0.4' margin='0.25rem 0' />

      {patientdata[id].problem_list.map((iterator) => (
        <ProblemsListElement key={iterator}>
          <ProblemName>
            <ImDiamonds color={theme.blue} />
            <TextSpan padding='0 0.5rem' weight='500'>
              {iterator}
            </TextSpan>
          </ProblemName>
        </ProblemsListElement>
      ))}
    </ReadingsCard>
  );
};

export default MedicalProblems;
