import {
  ImageContainer,
  ImgCircle,
  UserInfoDiv,
  UserProfileContainer,
  UserProfileElements,
  UserProfileLine,
} from './ user-profile.style';

import { Button } from 'antd';
import LogoutButton from '../../atoms/logout-button';
import TextSpan from '../../atoms/text-span';
import { patientdata } from '../../../utils/patients-data/patient-data';
import theme from '../../../global/theme/theme';
import { useParams } from 'react-router-dom';

//   import { capitalize, formatDate, getAge } from '../../../utils/helper.utils';
//   import { FhirClientContext } from '../../../contexts/fhir-client-context';

const UserProfile = (props) => {
  const { id } = useParams();
  return (
    <UserProfileContainer>
      {
        <UserProfileElements id='patient-profile-info'>
          <ImageContainer>
            <ImgCircle
              src={patientdata[id].info.imgsrc}
              alt='Profile'
              dimension='200px'
              borderSize='10px'
            />
            <UserProfileLine />
          </ImageContainer>
          {/* <Heading   level={2} color={theme.text.blue} margin='1rem 0 0.5rem 0'> */}
          <h2
            style={{
              fontSize: '23px',
              color: theme.text.blue,
              margin: '1rem 0 0.5rem 0',
            }}
          >
            {patientdata[id].info.First} &nbsp; {patientdata[id].info.Last}
          </h2>
          {/* </Heading> */}
          <UserInfoDiv>
            <TextSpan color='secondary' weight='900'>
              DOB: &nbsp;
            </TextSpan>
            <TextSpan weight='bold'>{patientdata[id].info.DOB}</TextSpan>
          </UserInfoDiv>
          <UserInfoDiv>
            <TextSpan color='secondary' weight='900'>
              AGE: &nbsp;
            </TextSpan>
            <TextSpan weight='bold'>{patientdata[id].info.Age}</TextSpan>
          </UserInfoDiv>
          <UserInfoDiv>
            <TextSpan color='secondary' weight='900'>
              SEX: &nbsp;
            </TextSpan>
            <TextSpan weight='bold'>{patientdata[id].info.Sex}</TextSpan>
          </UserInfoDiv>
          {id === 'Frank_Pharma_Tour' && (
            <Button
              style={{ marginTop: '15px', background: '#B5297D' }}
              type='primary'
              onClick={() => {
                window.scroll(0, 580);
                setTimeout(() => {
                  props.setTourOpen(true);
                  props.setTourIndex(0);
                }, 1000);
              }}
            >
              Start Tour
            </Button>
          )}
        </UserProfileElements>
      }
      <LogoutButton />
    </UserProfileContainer>
  );
};

export default UserProfile;
