import { LayoutBG, Main, UserProfileContainer } from './layout.style';

import React from 'react';
import UserProfile from './user-profile/user-profile';

const Layout = (props: any) => {
  return (
    <LayoutBG>
      <UserProfileContainer>
        <UserProfile
          setTourOpen={props.setTour}
          setTourIndex={props.setTourIndex}
        />
      </UserProfileContainer>
      <Main>{props.children}</Main>
    </LayoutBG>
  );
};

export default Layout;
