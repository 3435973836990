import UrlConstants from '../constants/url.constants';

/**
 * returns url for the api call
 * @param {String} usecase - usecase of the url
 * @returns {String} - generated backend api
 */

export const generateUrl = (usecase: string) => {
  let url: string = process.env.REACT_APP_BACKEND_GlOBAL_PREFIX || '';
  if (UrlConstants[usecase] && usecase === UrlConstants[usecase]['USECASE'])
    url += UrlConstants[usecase].URL;

  return url;
};

/**
 * returns url for the api call
 * @param {String} usecase - usecase of the url
 * @returns {String} - generated backend api
 */
export const updatePathParamsForApiUrl = (url: string, params: any) => {
  const updatedUrl: URL = new URL(url);
  params &&
    Object.keys(params).forEach((key) => {
      if (key.charAt(0) === '$' && updatedUrl.pathname.includes(key)) {
        updatedUrl.pathname = updatedUrl.pathname.replace(key, params[key]);
        delete params[key];
      }
    });

  return { url: updatedUrl.toString(), params };
};

/**
 * Util function to add query params to the url
 * @param {string} url - url
 * @param {Object} params - params
 */
export const addQueryParamsToUrl = (
  route: string,
  params: Record<string, string>,
  httpMethod: string,
  usecase: string = ''
) => {
  let pathname = route;
  if (!params) return route;
  if (httpMethod !== 'GET' && usecase === 'POST') {
    const updatedUrl = new URL(pathname);

    Object.keys(params).forEach((key) => {
      if (key.charAt(0) === '_') {
        updatedUrl.searchParams.set(key.split('_')[1], params[key]);
        delete params[key];
      }
    });
    return updatedUrl.toString();
  }

  Object.keys(params).forEach((key, index) => {
    if (index === 0) pathname += '?';
    else pathname += '&';
    const value = params[key];
    if (Array.isArray(value)) {
      value.map((each, i) =>
        i < value.length - 1
          ? (pathname += key + '=' + encodeURIComponent(each) + '&')
          : (pathname += key + '=' + encodeURIComponent(each))
      );
    } else pathname += key + '=' + encodeURIComponent(value);
  });
  return pathname;
};
