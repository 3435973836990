import '../tab/tab.css';

import {
  Accordion,
  AccordionIconAlignment,
  AccordionSection,
} from '../../accordion/accordion.style';
import {
  AdrData,
  SmartADRMedElementProps,
} from '../../../types/smart-adr.types';
import {
  FlexContainer,
  MedElementContainer,
  MedOverlay,
} from './med-element.style';
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md';
import {
  ModalAdrInfo,
  ModalCard,
  ModalCardMain,
} from '../modal/monograph-modal.style';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import HorizontalLine from '../../atoms/horizontal-line';
import { IndicationsTabs } from '../constants/indication-dosing';
import TextSpan from '../../atoms/text-span';

const IndicationDosingMedElement: React.FC<SmartADRMedElementProps> = ({
  name,
  dosage,
  adrDetails,
  highlight,
  monographReference,
  openHepaticMonographContent,
}) => {
  const [activeTab, setActiveTab] = useState<string>('SmartADR');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedAdr, setSelectedAdr] = useState<AdrData>({
    id: '',
    adrName: '',
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [adrObject, setAdrObject] = useState<any[] | null>(null);
  const [selectedAdrFrequency, setSelectedAdrFrequency] = useState<string>('');

  useEffect(() => {
    if (
      name === 'oxyCODONE (ROXICODONE) tablet' &&
      openHepaticMonographContent &&
      adrDetails['hepatic'] &&
      adrDetails['hepatic'].monographContent
    ) {
      setAdrObject(adrDetails.hepatic);
      setSelectedAdrFrequency('hepatic');
    }

    if (!openHepaticMonographContent) {
      setAdrObject(null);
      setSelectedAdrFrequency('');
    }
  }, [openHepaticMonographContent]);
  const TabTitle = ({ title, key }: { title: string; key: any }) => {
    return (
      <FlexContainer>
        <span>{title}</span>
        {(activeTab === '1' && title === 'Indication') ||
        (activeTab === '2' && title === 'Renal') ||
        (activeTab === '3' && title === 'Hepatic') ? (
          <MdOutlineArrowDropUp color={'black'} size={20} />
        ) : (
          <MdOutlineArrowDropDown color={'black'} size={20} />
        )}
      </FlexContainer>
    );
  };

  const handleTabSelect = (key: any) => {
    if (activeTab === key) {
      setSelectedAdr({
        id: '',
        adrName: '',
      });
      setActiveTab('smart adr');
      setSelectedAdrFrequency('');
    } else {
      if (key === '1') {
        setAdrObject(adrDetails.indication);
        setSelectedAdrFrequency('indication');
      }
      if (key === '2') {
        setAdrObject(adrDetails.renal);
        setSelectedAdrFrequency('renal');
      }
      if (key === '3') {
        setAdrObject(adrDetails.hepatic);
        setSelectedAdrFrequency('hepatic');
      }
      setActiveTab(key);
      setSelectedAdr({
        id: '',
        adrName: '',
      });
    }
  };

  return (
    <AccordionSection>
      <Accordion>
        <MedElementContainer>
          <TextSpan weight='bold'>{name}</TextSpan>
          <AccordionIconAlignment>
            <TextSpan color='secondary' padding='0.5rem 0rem' weight='bold'>
              {dosage}
            </TextSpan>
          </AccordionIconAlignment>
          {/* {!isEmpty(adrDetails) && ( */}
          <div
            style={{
              background: `linear-gradient(to right, ${
                highlight === 'red'
                  ? 'rgba(255, 237, 237, 1)'
                  : highlight === 'yellow'
                  ? 'rgba(254, 255, 237, 1)'
                  : highlight === 'green'
                  ? 'rgba(237, 255, 240, 1)'
                  : ''
              } 95%, transparent 50%)`,
              zIndex: '100',
              width: '100%',
            }}
          >
            <div id='Indication-dosing-by-medication'>
              <Tabs
                activeKey={activeTab}
                onSelect={handleTabSelect}
                id='uncontrolled-tab-example'
                color={highlight}
                className={`${adrDetails.renal && 'indicationDosingRenal'} ${
                  adrDetails.hepatic && 'indicationDosingHepatic'
                } ${
                  adrDetails.indication && 'indicationDosingIndication'
                } indication`}
              >
                {IndicationsTabs.map((frequency: any, indexTab: any) => (
                  <Tab
                    style={{ width: '100%' }}
                    className='custom-scrollbar'
                    eventKey={indexTab + 1}
                    title={
                      <TabTitle title={frequency.Title} key={indexTab + 1} />
                    }
                    disabled={
                      indexTab === 0
                        ? adrDetails.indication
                          ? false
                          : true
                        : indexTab === 1
                        ? adrDetails.renal
                          ? false
                          : true
                        : indexTab === 2
                        ? adrDetails.hepatic
                          ? false
                          : true
                        : false // Provide a boolean value when none of the conditions match
                    }
                  ></Tab>
                ))}
              </Tabs>
            </div>
          </div>
          {/* )} */}

          <HorizontalLine
            width='100%'
            opacity='0.25'
            margin='0.5rem 0rem 0rem 0rem'
          />
          {highlight && <MedOverlay highlight={highlight}></MedOverlay>}
          {selectedAdrFrequency && (
            <ModalCard
              highlight={'#FAF0F5'}
              style={{ width: '100%' }}
              id={`Oxycodone-Indication-Dosing`}
            >
              <ModalCardMain style={{ width: '100%' }}>
                <ModalAdrInfo style={{ padding: '0rem 0rem' }}>
                  {selectedAdrFrequency && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          adrDetails[selectedAdrFrequency].monographContent,
                      }}
                    />
                  )}
                </ModalAdrInfo>
              </ModalCardMain>
            </ModalCard>
          )}
          {selectedAdrFrequency && selectedAdrFrequency !== 'indication' && (
            <HorizontalLine
              width='100%'
              opacity='0.35'
              margin='0.5rem 0rem 0rem 0rem'
            />
          )}
        </MedElementContainer>
      </Accordion>
    </AccordionSection>
  );
};

export default IndicationDosingMedElement;
