import styled from "styled-components";

export const SummaryContainer = styled.div`
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  // width: 100%;
`;

export const CardsContainer = styled.div`
  display: flex;
  padding: 1rem 0;
  columns: 440px 4;
`;