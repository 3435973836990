import { HiBeaker } from 'react-icons/hi';
import HorizontalLine from '../atoms/horizontal-line';
import LabReading from './labs-reading/labs-reading';
import { ReadingContent } from './labs-card.style';
import ReadingsCard from '../atoms/readings-card/readings-card';
import TextSpan from '../atoms/text-span';
import { patientdata } from '../../utils/patients-data/patient-data';
import theme from '../../global/theme/theme';
import { useParams } from 'react-router-dom';
const LabsCard = () => {
  const { id } = useParams();

  // useEffect(async () => {

  //     if (sessionStorage.getItem(SessionStorageConstants.EPIC_ACCESS_TOKEN)) {
  //       setLoading(true);
  //       try {
  //         const latestObservations = await makeAuthenticatedApiCall(
  //           UrlConstants.GET_PATIENT_LAB.USECASE,
  //           HttpConstants.GET
  //         );
  //         setLabReadings(latestObservations.data.data);
  //       } catch (error) {
  //         console.error('Error fetching lab readings:', error);
  //       } finally {
  //         setLoading(false);
  //       }
  //     }
  // }, [sessionStorage.getItem(SessionStorageConstants.EPIC_ACCESS_TOKEN)]);

  return (
    <ReadingsCard
      color={theme.purple}
      title='BMP/CBC/INR'
      icon={<HiBeaker />}
      id='labs'
    >
      <TextSpan color='tertiary' padding='0.5rem'>
        (Most recent lab results)
      </TextSpan>
      <ReadingContent>
        {patientdata[id].labs.map((value) => (
          <LabReading
            key={value.loinc}
            name={value.name}
            value={value.value}
            rangeLow={value.rangeLow}
            rangeHigh={value.rangeHigh}
            effectiveDate={value.effectiveDateTime}
            id='labs'
          />
        ))}
      </ReadingContent>
      <HorizontalLine width='100%' opacity='0.25' />
    </ReadingsCard>
  );
};

export default LabsCard;
