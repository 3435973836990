import 'bootstrap/dist/css/bootstrap.min.css';
import '../tab/tab.css';

import {
  AdrDetails,
  AdrNameAndSeverity,
  CheckBox,
  TabContainer,
  TabHeading,
} from './by-type.style';
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md';
import {
  ModalAdrInfo,
  ModalCard,
  ModalCardMain,
} from '../modal/monograph-modal.style';
import React, { useEffect, useState } from 'react';

import { AdrData } from '../../../types/smart-adr.types';
import checkBox from '../constants/svg/checkBox.svg';
import redCheckBox from '../constants/svg/redCheckBox.svg';

const IndicationDoseByTpe: React.FC<any> = ({
  IndicationDosingdetails,
  OpenHepaticMonographContent,
  OpenHepaticTab,
}) => {
  const [selectedAdr, setSelectedAdr] = useState<AdrData>({
    id: '',
    adrName: '',
  });
  useEffect(() => {
    if (OpenHepaticTab) setActiveTab('Hepatic');
    else setActiveTab('');
  }, [OpenHepaticTab]);

  useEffect(() => {
    if (OpenHepaticMonographContent)
      setSelectedAdr({
        id: '0',
        adrName: Object.keys(IndicationDosingdetails.hepatic[0])[0],
      });
    else
      setSelectedAdr({
        id: '',
        adrName: '',
      });
  }, [OpenHepaticMonographContent]);
  const [activeTab, setActiveTab] = useState<string>('');
  return (
    <div id='Indication-dosing-by-type'>
      <TabContainer
        style={{
          pointerEvents:
            IndicationDosingdetails.indication.length === 0 ? 'none' : 'auto',
        }}
        background={
          IndicationDosingdetails.indication.length > 0 ? '#FFEDED' : ''
        }
        // background='#FFEDED'
        border='black'
        onClick={() => {
          if (activeTab === 'Indication') {
            setActiveTab('');
          } else {
            setActiveTab('Indication');
          }
          setSelectedAdr({
            id: '',
            adrName: '',
          });
        }}
      >
        <TabHeading>
          {' '}
          Indication (
          <span
            style={{
              color: IndicationDosingdetails.indication.length > 0 ? 'red' : '',
            }}
          >
            {IndicationDosingdetails.indication.length}
          </span>
          )
        </TabHeading>
        {activeTab === 'Indication' ? (
          <MdOutlineArrowDropUp color={'black'} size={20} />
        ) : (
          <MdOutlineArrowDropDown color={'black'} size={20} />
        )}
      </TabContainer>

      {activeTab === 'Indication' &&
        IndicationDosingdetails.indication.map(
          (indicationDetail: any, index: any) => (
            <div>
              <div
                onClick={() => {
                  if (selectedAdr.id === index) {
                    setSelectedAdr({
                      id: '',
                      adrName: '',
                    });
                  } else {
                    setSelectedAdr({
                      id: index,
                      adrName: Object.keys(indicationDetail)[0],
                    });
                  }
                }}
              >
                <AdrDetails
                  border={'1px solid #FFEDED'}
                  hoverBackground={
                    selectedAdr.id === index ? '#FFC7C7' : '#ffeded'
                  }
                  background={selectedAdr.id === index ? '#FFC7C7' : ' #ffffff'}
                >
                  <CheckBox>
                    {selectedAdr.id === index ? (
                      <img
                        style={{ marginBottom: '6px' }}
                        src={redCheckBox}
                        alt=''
                      />
                    ) : (
                      <img
                        style={{ marginBottom: '6px' }}
                        src={checkBox}
                        alt=''
                      />
                    )}
                  </CheckBox>
                  <AdrNameAndSeverity>
                    {indicationDetail.medicationName}
                  </AdrNameAndSeverity>
                </AdrDetails>
              </div>

              {activeTab === 'Indication' &&
                selectedAdr.id !== '' &&
                selectedAdr.id === index && (
                  <ModalCard highlight={'#ffeded'} style={{ width: '100%' }}>
                    <ModalCardMain style={{ width: '100%' }}>
                      <ModalAdrInfo style={{ padding: '0rem 0rem' }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              IndicationDosingdetails['indication'][
                                selectedAdr.id
                              ].monographContent,
                          }}
                        />
                      </ModalAdrInfo>
                    </ModalCardMain>
                  </ModalCard>
                )}
            </div>
          )
        )}
      {/* {activeTab === 'Indication' && selectedAdr.id !== '' && (
        <ModalCard highlight={'#ffeded'} style={{ width: '100%' }}>
          <ModalCardMain style={{ width: '100%' }}>
            <ModalAdrInfo style={{ padding: '0rem 0rem' }}>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    IndicationDosingdetails['indication'][selectedAdr.id]
                      .monographContent,
                }}
              />
            </ModalAdrInfo>
          </ModalCardMain>
        </ModalCard>
      )} */}
      <TabContainer
        style={{
          pointerEvents:
            IndicationDosingdetails.renal.length === 0 ? 'none' : 'auto',
        }}
        background={IndicationDosingdetails.renal.length > 0 ? '#FFEDED' : ''}
        border='black'
        onClick={() => {
          if (activeTab === 'Renal') {
            setActiveTab('');
          } else {
            setActiveTab('Renal');
          }
          setSelectedAdr({
            id: '',
            adrName: '',
          });
        }}
      >
        <TabHeading>
          {' '}
          Renal (
          <span
            style={{
              color: IndicationDosingdetails.renal.length > 0 ? 'red' : '',
            }}
          >
            {IndicationDosingdetails.renal.length}
          </span>
          )
        </TabHeading>
        {activeTab === 'Renal' ? (
          <MdOutlineArrowDropUp color={'black'} size={20} />
        ) : (
          <MdOutlineArrowDropDown color={'black'} size={20} />
        )}
      </TabContainer>
      {activeTab === 'Renal' &&
        IndicationDosingdetails.renal.map(
          (indicationDetail: any, index: any) => (
            <div>
              <div
                onClick={() => {
                  if (selectedAdr.id === index) {
                    setSelectedAdr({
                      id: '',
                      adrName: '',
                    });
                  } else {
                    setSelectedAdr({
                      id: index,
                      adrName: Object.keys(indicationDetail)[0],
                    });
                  }
                }}
              >
                <AdrDetails
                  border={'1px solid #FFEDED'}
                  hoverBackground={
                    selectedAdr.id === index ? '#FFC7C7' : '#ffeded'
                  }
                  background={selectedAdr.id === index ? '#FFC7C7' : ' #ffffff'}
                >
                  <CheckBox>
                    {selectedAdr.id === index ? (
                      <img
                        style={{ marginBottom: '6px' }}
                        src={redCheckBox}
                        alt=''
                      />
                    ) : (
                      <img
                        style={{ marginBottom: '6px' }}
                        src={checkBox}
                        alt=''
                      />
                    )}
                  </CheckBox>
                  <AdrNameAndSeverity>
                    {indicationDetail.medicationName}
                  </AdrNameAndSeverity>
                </AdrDetails>
              </div>
              {activeTab === 'Renal' &&
                selectedAdr.id !== '' &&
                selectedAdr.id === index && (
                  <ModalCard highlight={'#ffeded'} style={{ width: '100%' }}>
                    <ModalCardMain style={{ width: '100%' }}>
                      <ModalAdrInfo style={{ padding: '0rem 0rem' }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              IndicationDosingdetails['renal'][selectedAdr.id]
                                .monographContent,
                          }}
                        />
                      </ModalAdrInfo>
                    </ModalCardMain>
                  </ModalCard>
                )}
            </div>
          )
        )}
      {/* {activeTab === 'Renal' && selectedAdr.id !== '' && (
        <ModalCard highlight={'#ffeded'} style={{ width: '100%' }}>
          <ModalCardMain style={{ width: '100%' }}>
            <ModalAdrInfo style={{ padding: '0rem 0rem' }}>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    IndicationDosingdetails['renal'][selectedAdr.id]
                      .monographContent,
                }}
              />
            </ModalAdrInfo>
          </ModalCardMain>
        </ModalCard>
      )} */}
      <div id='Indication-dosing-by-hepatic'>
        <TabContainer
          style={{
            pointerEvents:
              IndicationDosingdetails.hepatic.length === 0 ? 'none' : 'auto',
          }}
          background={
            IndicationDosingdetails.hepatic.length > 0 ? '#FFEDED' : ''
          }
          border='black'
          onClick={() => {
            if (activeTab === 'Hepatic') {
              setActiveTab('');
            } else {
              setActiveTab('Hepatic');
            }
            setSelectedAdr({
              id: '',
              adrName: '',
            });
          }}
        >
          <TabHeading>
            {' '}
            Hepatic (
            <span
              style={{
                color: IndicationDosingdetails.hepatic.length > 0 ? 'red' : '',
              }}
            >
              {IndicationDosingdetails.hepatic.length}
            </span>
            )
          </TabHeading>
          {activeTab === 'Hepatic' ? (
            <MdOutlineArrowDropUp color={'black'} size={20} />
          ) : (
            <MdOutlineArrowDropDown color={'black'} size={20} />
          )}
        </TabContainer>

        {activeTab === 'Hepatic' &&
          IndicationDosingdetails.hepatic.map((adrDetail: any, index: any) => (
            <div>
              <div
                onClick={() => {
                  if (selectedAdr.id === index) {
                    setSelectedAdr({
                      id: '',
                      adrName: '',
                    });
                  } else {
                    setSelectedAdr({
                      id: index,
                      adrName: Object.keys(adrDetail)[0],
                    });
                  }
                }}
              >
                <AdrDetails
                  border={'1px solid #FFEDED'}
                  hoverBackground={
                    selectedAdr.id === index ? '#FFC7C7' : '#ffeded'
                  }
                  background={selectedAdr.id === index ? '#FFC7C7' : ' #ffffff'}
                >
                  <CheckBox>
                    {parseInt(selectedAdr.id) === index ? (
                      <img
                        style={{ marginBottom: '6px' }}
                        src={redCheckBox}
                        alt=''
                      />
                    ) : (
                      <img
                        style={{ marginBottom: '6px' }}
                        src={checkBox}
                        alt=''
                      />
                    )}
                  </CheckBox>
                  <AdrNameAndSeverity>
                    {adrDetail.medicationName}
                  </AdrNameAndSeverity>
                </AdrDetails>
              </div>
              <div id='aspirin-monograph-content'>
                {activeTab === 'Hepatic' &&
                  selectedAdr.id !== '' &&
                  parseInt(selectedAdr.id) === index && (
                    <ModalCard highlight={'#ffeded'} style={{ width: '100%' }}>
                      <ModalCardMain style={{ width: '100%' }}>
                        <ModalAdrInfo style={{ padding: '0rem 0rem' }}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                IndicationDosingdetails['hepatic'][
                                  selectedAdr.id
                                ].monographContent,
                            }}
                          />
                        </ModalAdrInfo>
                      </ModalCardMain>
                    </ModalCard>
                  )}
              </div>
            </div>
          ))}
      </div>
      {/* {activeTab === 'Hepatic' && selectedAdr.id !== '' && (
        <ModalCard highlight={'#ffeded'} style={{ width: '100%' }}>
          <ModalCardMain style={{ width: '100%' }}>
            <ModalAdrInfo style={{ padding: '0rem 0rem' }}>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    IndicationDosingdetails['hepatic'][selectedAdr.id]
                      .monographContent,
                }}
              />
            </ModalAdrInfo>
          </ModalCardMain>
        </ModalCard>
      )} */}
    </div>
  );
};

export default IndicationDoseByTpe;
