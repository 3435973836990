import '../tab/tab.css';

import {
  Accordion,
  AccordionContent,
  AccordionIconAlignment,
  AccordionSection,
} from '../../accordion/accordion.style';
import {
  AdrData,
  SmartADRMedElementProps,
} from '../../../types/smart-adr.types';
import {
  AdrDetails,
  AdrNameAndSeverity,
  CheckBox,
  FlexContainer,
  MedElementContainer,
  MedOverlay,
} from './med-element.style';
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md';
import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import HorizontalLine from '../../atoms/horizontal-line';
import SmartADRModal from '../modal/monograph-modal';
import TextSpan from '../../atoms/text-span';
import beersSvg from '../constants/svg/beers.svg';
import checkBox from '../constants/svg/checkBox.svg';
import { contraindicationTabs } from '../constants/contraindication';
import redCheckBox from '../constants/svg/redCheckBox.svg';

const ContraIndicationMedElement: React.FC<SmartADRMedElementProps> = ({
  name,
  dosage,
  adrDetails,
  highlight,
  monographReference,
  beers,
}) => {
  const [activeTab, setActiveTab] = useState<string>('SmartADR');
  const [selectedAdr, setSelectedAdr] = useState<AdrData>({
    id: '',
    adrName: '',
  });
  const [adrObject, setAdrObject] = useState<any[] | null>(null);
  const [selctedAdrFrequency, setSelectedAdrFrequency] = useState<string>('');
  const TabTitle = ({
    title,
    key,
    className,
  }: {
    title: string;
    key: any;
    className: string;
  }) => {
    let adrLength = 0;
    if (title === 'Contraindication' && adrDetails.contraindication) {
      adrLength = adrDetails.contraindication.length;
    }
    // if (title === 'Black Box Warning' && adrDetails.blackBoxWarning) {
    //   adrLength = adrDetails.blackBoxWarning.length;
    // }
    // if (title === 'Pregnancy/Lactation' && adrDetails.pregnancyLactation) {
    //   adrLength = adrDetails.pregnancyLactation.length;
    // }
    return (
      <FlexContainer className={className}>
        {/* <img
          src={
            title === 'Severe'
              ? rareSvg
              : title === 'Major'
              ? infrequentSvg
              : title === 'Moderate'
              ? commonSvg
              : unknownSvg
          }
          alt=''
        /> */}

        {title === 'Contraindication' && (
          <span style={{ color: 'black' }}>
            (
            {title === 'Contraindication' && (
              <span style={{ color: adrLength > 0 ? 'red' : '' }}>
                {adrLength}
              </span>
            )}
            )
          </span>
        )}
        <span>{title}</span>
        {(activeTab === '1' && title === 'Contraindication') ||
        (activeTab === '2' && title === 'Black Box Warning') ||
        (activeTab === '3' && title === 'Pregnancy/Lactation') ? (
          <MdOutlineArrowDropUp color={'black'} size={20} />
        ) : (
          <MdOutlineArrowDropDown color={'black'} size={20} />
        )}
      </FlexContainer>
    );
  };

  const handleTabSelect = (key: any) => {
    if (activeTab === key) {
      setSelectedAdr({
        id: '',
        adrName: '',
      });
      setActiveTab('smart adr');
    } else {
      if (key === '1') {
        setAdrObject(adrDetails.contraindication);
        setSelectedAdrFrequency('contraindication');
      }
      if (key === '2') {
        setAdrObject(adrDetails.blackBoxWarning);
        setSelectedAdrFrequency('blackBoxWarning');
      }
      if (key === '3') {
        setAdrObject(adrDetails.pregnancyLactation);
        setSelectedAdrFrequency('pregnancyLactation');
      }
      setActiveTab(key);
      setSelectedAdr({
        id: '',
        adrName: '',
      });
    }
  };

  return (
    <AccordionSection>
      <Accordion>
        <MedElementContainer>
          <TextSpan weight='bold'>{name}</TextSpan>
          <AccordionIconAlignment>
            <TextSpan color='secondary' padding='0.5rem 0rem' weight='bold'>
              {dosage}
            </TextSpan>
            {beers && beers === 'true' && (
              <img style={{ marginBottom: '6px' }} src={beersSvg} alt='' />
            )}
          </AccordionIconAlignment>
          {/* {!isEmpty(adrDetails) && ( */}
          <div
            style={{
              // background: `linear-gradient(to right, ${
              //   highlight === 'red'
              //     ? 'rgba(255, 237, 237, 1)'
              //     : highlight === 'yellow'
              //     ? 'rgba(254, 255, 237, 1)'
              //     : highlight === 'green'
              //     ? 'rgba(237, 255, 240, 1)'
              //     : ''
              // } 95%, transparent 50%)`,
              zIndex: '100',
              width: '100%',
            }}
          >
            <div id='contraindication-by-medication'>
              <Tabs
                activeKey={activeTab}
                onSelect={handleTabSelect}
                id='uncontrolled-tab-example'
                className={` ${
                  adrDetails.contraindication && 'contraindication'
                } ${adrDetails.blackBoxWarning && 'contraindicationBlackBox'}`}
              >
                {contraindicationTabs.map((frequency: any, indexTab: any) => (
                  <Tab
                    style={{ background: 'red' }}
                    eventKey={indexTab + 1}
                    title={
                      <TabTitle
                        className='tabtitlecheck'
                        title={frequency.Title}
                        key={indexTab + 1}
                      />
                    }
                    disabled={
                      indexTab === 0
                        ? adrDetails.contraindication
                          ? false
                          : true
                        : indexTab === 1
                        ? adrDetails.blackBoxWarning
                          ? false
                          : true
                        : indexTab === 2
                        ? adrDetails.pregnancyLactation
                          ? false
                          : true
                        : false // Provide a boolean value when none of the conditions match
                    }
                  >
                    {adrObject &&
                      adrObject.map((adrDetail: any, index: any) => (
                        <div>
                          <div
                            onClick={() => {
                              if (selectedAdr.id === index) {
                                setSelectedAdr({
                                  id: '',
                                  adrName: '',
                                });
                              } else {
                                setSelectedAdr({
                                  id: index,
                                  adrName: Object.keys(adrDetail)[0],
                                });
                              }
                            }}
                          >
                            <AdrDetails
                              border={'1px solid #FFEDED'}
                              hoverBackground={
                                selectedAdr.adrName ===
                                Object.keys(adrDetail)[0]
                                  ? '#FFC7C7'
                                  : '#ffeded'
                              }
                              background={
                                selectedAdr.adrName ===
                                Object.keys(adrDetail)[0]
                                  ? '#FFC7C7'
                                  : ' #ffffff'
                              }
                            >
                              <CheckBox>
                                {selectedAdr.adrName ===
                                Object.keys(adrDetail)[0] ? (
                                  <img
                                    style={{ marginBottom: '6px' }}
                                    src={redCheckBox}
                                    alt=''
                                  />
                                ) : (
                                  <img
                                    style={{ marginBottom: '6px' }}
                                    src={checkBox}
                                    alt=''
                                  />
                                )}
                              </CheckBox>
                              <AdrNameAndSeverity>
                                {Object.keys(adrDetail)[0]}
                              </AdrNameAndSeverity>
                              <AdrNameAndSeverity>
                                {
                                  adrDetail[Object.keys(adrDetail)[0]][0]
                                    .severity
                                }
                              </AdrNameAndSeverity>
                            </AdrDetails>
                          </div>
                          {selectedAdr.adrName && selectedAdr.id === index && (
                            <AccordionContent
                              onClick={(event) => event.stopPropagation()}
                            >
                              <SmartADRModal
                                adrDetails={
                                  adrDetails[selctedAdrFrequency][
                                    selectedAdr.id
                                  ][selectedAdr.adrName]
                                }
                                monographReference={monographReference}
                                monographType='contraindication'
                              />

                              <HorizontalLine
                                width='100%'
                                opacity='0.35'
                                margin='0.5rem 0rem 0rem 0rem'
                              />
                            </AccordionContent>
                          )}
                        </div>
                      ))}
                  </Tab>
                ))}
              </Tabs>
            </div>
          </div>

          <HorizontalLine
            width='100%'
            opacity='0.25'
            margin='0.5rem 0rem 0rem 0rem'
          />
          {highlight && <MedOverlay highlight={highlight}></MedOverlay>}
          {/* {selectedAdr.adrName && (
            <AccordionContent onClick={(event) => event.stopPropagation()}>
              <SmartADRModal
                adrDetails={
                  adrDetails[selctedAdrFrequency][selectedAdr.id][
                    selectedAdr.adrName
                  ]
                }
                monographReference={monographReference}
                monographType='contraindication'
              />

              <HorizontalLine
                width='100%'
                opacity='0.35'
                margin='0.5rem 0rem 0rem 0rem'
              />
            </AccordionContent>
          )} */}
        </MedElementContainer>
      </Accordion>
    </AccordionSection>
  );
};

export default ContraIndicationMedElement;
