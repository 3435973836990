import { LogoutButtonProps } from '../../types/styles.types';
import { MdLogout } from 'react-icons/md';
import React from 'react';
import TextSpan from './text-span';
import styled from 'styled-components';
import theme from '../../global/theme/theme';
import { useNavigate } from 'react-router-dom';

const LogoutButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  font-size: 1rem;
  padding: 1rem 2rem;
  position: absolute;
  top: 1rem;
  right: 5%;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 8px;

  &:hover {
    background-color: ${theme.purple}20;
  }
`;

const LogoutButton: React.FC<LogoutButtonProps> = ({ history }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/', { replace: true });
  };

  return (
    <LogoutButtonWrapper onClick={handleLogout}>
      <MdLogout />
      <TextSpan color='secondary' weight='600' padding='0 1rem'>
        Back
      </TextSpan>
    </LogoutButtonWrapper>
  );
};

export default LogoutButton;
