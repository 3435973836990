import styled, { keyframes } from 'styled-components';

import { BasicSpinnerProps } from '../../types/styles.types';
import React from 'react';
import TextSpan from './text-span';
import theme from '../../global/theme/theme';

const BasicSpinnerContainer = styled.div<{ height: string; padding: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.height};
  width: 100%;
  padding: ${(props) => props.padding};
`;

const animate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SpinnerElement = styled.div<{
  width: string;
  height: string;
  thickness: string;
  color: string;
}>`
  box-sizing: border-box;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin-right: 1rem;
  border: ${(props) => props.thickness} solid #fff;
  border-radius: 50%;
  animation: ${animate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${(props) => props.color} transparent transparent transparent;
`;

const BasicSpinner: React.FC<BasicSpinnerProps> = ({
  color = theme.primary,
  width = '3rem',
  height = '3rem',
  thickness = '4px',
  padding = '0',
  label,
}) => {
  return (
    <BasicSpinnerContainer height={height} padding={padding}>
      <SpinnerElement
        width={width}
        height={height}
        color={color}
        thickness={thickness}
      ></SpinnerElement>
      {label && <TextSpan>{label}</TextSpan>}
    </BasicSpinnerContainer>
  );
};

export default BasicSpinner;
