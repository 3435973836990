import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md';
import {
  ModalAdrInfo,
  ModalCard,
  ModalCardMain,
  ModalTab,
  ModalTabContainer,
} from './monograph-modal.style';

import BasicSpinner from '../../atoms/basic-spinner';
import { MdContentCopy } from 'react-icons/md';
import React from 'react';
import { SmartADRModalProps } from '../../../types/smart-adr.types';
import TextSpan from '../../atoms/text-span';
import moment from 'moment';
import { renderToString } from 'react-dom/server';
import theme from '../../../global/theme/theme';

const SmartADRModal: React.FC<SmartADRModalProps> = ({
  id,
  adrDetails,
  monographReference,
  monographType,
}) => {
  const [activeAdrIndex, setActiveAdrIndex] = React.useState<number>(0);
  const [activeColor, setActiveColor] = React.useState<string>(theme.white);
  const [monograph, setMonograph] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(true);
  const iconHtml = renderToString(<MdContentCopy color='black' />);

  const getMonographHeadline = React.useCallback(() => {
    return new Promise((resolve, reject) => {
      let generalInformation = adrDetails[activeAdrIndex].monographContent;
      function copyToClipboard(text: string) {
        navigator.clipboard.writeText(text);
      }
      generalInformation = generalInformation
        .replace(/&amp;lt;/g, '<')
        .replace(/&amp;gt;/g, '>');
      generalInformation = generalInformation.replace(
        /&lt;span\b[^>]*>(.*?)&lt;\/span>/g,
        (match, number) => {
          const randomNumber = Date.now();
          return `
          <div style="display: inline-block;">
          <span style="color: blue;" title="${monographReference[number]}">
            [${number}] <span onclick="(function() {
              ${copyToClipboard.toString()};
              const referenceText = '${monographReference[number]}';
              const tempTextarea = document.createElement('textarea');
              tempTextarea.value = referenceText;
              document.body.appendChild(tempTextarea);
              tempTextarea.select();
              document.execCommand('copy');
              document.body.removeChild(tempTextarea);
              const tooltipElement = document.getElementById('tooltip-${number}-${randomNumber}');
              if (tooltipElement) {
                tooltipElement.innerText = 'Text copied!';
                tooltipElement.style.opacity = 1;
                setTimeout(function() {
                  tooltipElement.innerText = '';
                  tooltipElement.style.opacity = 0;
                }, 1000);
              }
            })()" title="Click To Copy Reference Text Of ${number}" style="cursor: pointer; padding-top: 25px;">
              ${iconHtml}
            </span>
            <span id="tooltip-${number}-${randomNumber}" style="display: inline-block; font-size: 12px; color: grey; opacity: 0; transition: opacity 0.5s ease-in-out;"></span>
          </span>
        </div>


        `;
        }
      );

      resolve(generalInformation);
    });
  }, [activeAdrIndex, adrDetails]);

  React.useEffect(() => {
    setLoading(true);

    let color = activeColor;
    if (
      adrDetails[activeAdrIndex].frequency &&
      (adrDetails[activeAdrIndex].frequency === 'common' ||
        adrDetails[activeAdrIndex].frequency === 'severe')
    )
      color = theme.modal.highlightRed;
    if (
      adrDetails[activeAdrIndex].frequency &&
      (adrDetails[activeAdrIndex].frequency === 'infrequent' ||
        adrDetails[activeAdrIndex].frequency === 'moderate')
    )
      color = theme.modal.highlightYellow;
    if (
      adrDetails[activeAdrIndex].frequency &&
      (adrDetails[activeAdrIndex].frequency === 'rare' ||
        adrDetails[activeAdrIndex].frequency === 'minor')
    )
      color = theme.lightGreen;
    if (
      adrDetails[activeAdrIndex].frequency &&
      adrDetails[activeAdrIndex].frequency === 'major'
    )
      color = '#FFF2D9';

    if (monographType === 'contraindication') color = theme.modal.highlightRed;
    setActiveColor(color);

    getMonographHeadline()
      .then((res) => {
        setMonograph(res as string);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ', err);
        setLoading(false);
      });
  }, [activeAdrIndex, adrDetails]);

  const handleAdrClick = (
    index: number,
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    event.stopPropagation();
    setActiveAdrIndex(index);
  };

  return (
    <ModalCard highlight={activeColor} id={id}>
      <ModalCardMain>
        {monographType !== 'drug-drug-interaction' &&
          monographType !== 'contraindication' && (
            <ModalTabContainer>
              {adrDetails.map((iterator, index) => {
                let color = theme.mudGreen;
                if (iterator.frequency === 'common') color = theme.red;
                if (iterator.frequency === 'infrequent')
                  color = theme.modal.deepYellow;
                // if (iterator.frequency !== 'rare') {
                let Icon = <></>;
                if (iterator.effect === 'increase')
                  Icon = <MdOutlineArrowDropUp color={color} size={20} />;
                if (iterator.effect === 'decrease')
                  Icon = <MdOutlineArrowDropDown color={color} size={20} />;
                return (
                  <ModalTab
                    key={index}
                    onClick={(event) => handleAdrClick(index, event)}
                    active={index === activeAdrIndex}
                    color={color}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <TextSpan overwriteColor={color} weight='bold'>
                        {iterator.labName}
                      </TextSpan>
                      <TextSpan overwriteColor={color} font='14px'>
                        {moment
                          .utc(iterator.effectiveDateTime)
                          .format('MM/DD/YYYY')}
                      </TextSpan>
                    </div>
                    {Icon}
                  </ModalTab>
                );
                // }
              })}
            </ModalTabContainer>
          )}
        <ModalAdrInfo
          style={{
            padding:
              monographType === 'drug-drug-interaction' ||
              monographType === 'contraindication'
                ? '1rem 0rem'
                : '',
          }}
        >
          {loading ? (
            <BasicSpinner />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: monograph }} />
          )}
        </ModalAdrInfo>
      </ModalCardMain>
    </ModalCard>
  );
};

export default SmartADRModal;
