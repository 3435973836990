import { Route, Routes } from 'react-router-dom';

import HomePage from './pages/home-page/home-page';
import Launcher from './pages/launcher/launcher';
import { ThemeProvider } from 'styled-components';
import theme from './global/theme/theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
      <Route path='/home/:id' element={<HomePage />}></Route>
        <Route path='/' element={<Launcher />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
