import React, { useEffect, useState } from 'react';

import { Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

/**
 * Typically the launch page is an empty page with a `SMART.authorize` call in it.
 */
const Launcher: React.FC = () => {
  const [showStandardPatients, setShowStandardPatients] = useState<
    string | null
  >('false');
  const location = useLocation();
  useEffect(() => {
    const getQueryParam = (name: string) => {
      const params = new URLSearchParams(location.search);
      return params.get(name);
    };
    const showStandardPatients = getQueryParam('show-standard-patients');
    setShowStandardPatients(showStandardPatients);
  }, [location.search]);

  return (
    <div
      style={{
        backgroundColor: '#FAF3E0',
        minHeight: '100vh',
        paddingTop: '80px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '15px',
        }}
      >
        <img
          height={'48px'}
          width={'225px'}
          alt=''
          src='https://pharmcorps.com/static/logo-header-fe8225a4185985a597c04aeff7ee8e2b.png'
        />
      </div>
      {/* <div style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
        <ClippedAvatar
          id={'John_Doe'}
          name={'John Doe'}
          imageUrl={
            'https://cdn5.vectorstock.com/i/1000x1000/78/14/tiny-cute-cartoon-patient-man-character-broken-vector-26027814.jpg'
          }
        ></ClippedAvatar>
        <ClippedAvatar
          id={'Frank_Pharma'}
          name={'Frank Pharma'}
          imageUrl={
            'https://cdn5.vectorstock.com/i/1000x1000/29/94/sad-boy-on-crutches-with-broken-leg-cartoon-vector-46902994.jpg'
          }
        ></ClippedAvatar>
        <ClippedAvatar
          id={'Wendy_Williams'}
          name={'Wendy Williams'}
          imageUrl={
            'https://thumbs.dreamstime.com/b/kid-boy-fracture-arm-vector-kid-boy-fracture-arm-vector-child-food-cartoon-health-bone-illness-leg-illustration-accident-157366189.jpg'
          }
        ></ClippedAvatar>
      </div> */}

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
          width: '100vw',
        }}
      >
        <Table style={{ width: '50%' }} striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Age</th>
              <th>Bed</th>
              <th>Chief Complaint</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Wendy Williams</td>
              <td>52</td>
              <td>56</td>
              <td>Foot Ulcer, AKI</td>
              <td>
                <a href='/home/Wendy_Williams'>Patient Chart</a>
              </td>
            </tr>
            {showStandardPatients === 'true' && (
              <tr>
                <td>Wendy Williams Standard</td>
                <td>52</td>
                <td>56</td>
                <td>Foot Ulcer, AKI</td>
                <td>
                  <a href='/home/Wendy_Williams_Standard'>Patient Chart</a>
                </td>
              </tr>
            )}

            <tr>
              <td>Frank Pharma</td>
              <td>69</td>
              <td>35</td>
              <td>Liver Failure</td>
              <td>
                <a href='/home/Frank_Pharma'>Patient Chart</a>
              </td>
            </tr>
            {showStandardPatients === 'true' && (
              <tr>
                <td>Frank Pharma Standard</td>
                <td>69</td>
                <td>35</td>
                <td>Liver Failure</td>
                <td>
                  <a href='/home/Frank_Pharma_Standard'>Patient Chart</a>
                </td>
              </tr>
            )}
            <tr>
              <td>John Doe</td>
              <td>49</td>
              <td>34</td>
              <td>Headache, Palpitations</td>
              <td>
                <a href='/home/John_Doe'>Patient Chart</a>
              </td>
            </tr>

            {process.env.REACT_APP_SHOW_TOUR === 'true' && (
              <tr>
                <td>Frank Pharma</td>
                <td>69</td>
                <td>35</td>
                <td>Liver Failure</td>
                <td>
                  <a href='/home/Frank_Pharma_Tour'>Start Tour</a>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Launcher;
