import { MonographCardProps, ReferenceData } from '../../types/smart-adr.types';
import React, { useEffect } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

import BasicSpinner from '../atoms/basic-spinner';
import ContraIndicationMedElement from './by-medication/contraindication';
import ContraindicationByTpe from './by-type/contraindication';
import DrugDrugInteractionByType from './by-type/drug-drug-interaction';
import DrugDrugInterationMedElement from './by-medication/drug-drug-interactions';
import IndiactionDosingMedElement from './by-medication/indication-dosing';
import IndicationDoseByTpe from './by-type/indication-dosing';
import SmartADRElement from './by-medication/smart-adr';
import SmartAdrByCondition from './by-condition/smart-adr';
import SmartAdrByTpe from './by-type/smart-adr';
import { monographData } from '../../utils/patients-data/monograph-data';
import { patientdata } from '../../utils/patients-data/patient-data';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

const MonographCard: React.FC<MonographCardProps> = ({
  MonographType,
  OpenHepaticMonographContent,
  alertType,
  setAlertType,
  OpenHepaticTab,
  openSmartADrMonographContent,
  openSmartAdrTab,
  openDdiTab,
  openDdiMonographContent,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [monographReference, setMonographReference] = useState<ReferenceData>(
    {}
  );
  const params: any = useParams();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [dateRange, setDateRange] = useState<any>([null, null]);
  // const [startDate, endDate] = dateRange;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const maxDate = new Date();
  // const [value, setValue] = useState('medication');

  useEffect(() => {
    if (params.id !== 'Frank_Pharma_Tour') {
      setAlertType('medication');
    }
  }, [MonographType]);

  return (
    <>
      <ToggleButtonGroup
        id='alert-type-tabs'
        type='radio'
        name='options'
        value={alertType}
        style={{ width: '100%', padding: '10px 30px 10px 30px' }}
      >
        {/* <div id='alert-by-medication' style={{ width: '100%' }}> */}
        <ToggleButton
          style={{
            width: '100%',
            background: '#FAF0F5',
            color: '#B5307D',
            opacity: alertType === 'medication' ? '1.0' : '0.4',
            border:
              alertType === 'medication'
                ? '1px solid #B5307D'
                : '1px solid #FAF0F5',
          }}
          onClick={() => {
            setAlertType('medication');
          }}
          value='medication'
        >
          By Medication
        </ToggleButton>
        {/* </div> */}

        {/* <div id='alert-by-type' style={{ width: '100%' }}> */}
        <ToggleButton
          style={{
            width: '100%',
            background: '#FAF0F5',
            color: '#B5307D',
            opacity: alertType === 'type' ? '1.0' : '0.4',
            border:
              alertType === 'type' ? '1px solid #B5307D' : '1px solid #FAF0F5',
          }}
          onClick={() => {
            setAlertType('type');
          }}
          value='type'
        >
          {MonographType === 'ADR'
            ? 'By Frequency'
            : MonographType === 'DDI'
            ? 'By Severity'
            : 'By Type'}
        </ToggleButton>
        {/* </div> */}
        {/* <div id='alert-by-condition' style={{ width: '100%' }}> */}
        {MonographType === 'ADR' && (
          <ToggleButton
            style={{
              width: '100%',
              background: '#FAF0F5',
              color: '#B5307D',
              opacity: alertType === 'condition' ? '1.0' : '0.4',
              border:
                alertType === 'condition'
                  ? '1px solid #B5307D'
                  : '1px solid #FAF0F5',
            }}
            onClick={() => {
              setAlertType('condition');
            }}
            value='condition'
          >
            By Condition
          </ToggleButton>
        )}
        {/* </div> */}
      </ToggleButtonGroup>
      {loading ? (
        <BasicSpinner label='Loading SmartADR Results...' padding='4rem 0' />
      ) : MonographType === 'Indication/Dosing' ? (
        alertType === 'medication' ? (
          monographData[params.id].indication_dosing.by_medication.map(
            (medication: any, index: any) => {
              return (
                <IndiactionDosingMedElement
                  name={medication['name']}
                  dosage={medication['dosageInstruction']}
                  adrDetails={
                    medication['adrLabDetails']
                      ? medication['adrLabDetails']
                      : []
                  }
                  highlight={'white'}
                  monographReference={monographReference}
                  openHepaticMonographContent={OpenHepaticMonographContent}
                />
              );
            }
          )
        ) : (
          <IndicationDoseByTpe
            IndicationDosingdetails={
              monographData[params.id].indication_dosing.by_type
            }
            OpenHepaticMonographContent={OpenHepaticMonographContent}
            OpenHepaticTab={OpenHepaticTab}
          />
        )
      ) : MonographType === 'DDI' ? (
        alertType === 'medication' ? (
          monographData[params.id].drug_drug_interaction.by_medication.map(
            (medication: any) => {
              return (
                <DrugDrugInterationMedElement
                  key={medication['id']}
                  name={medication['name']}
                  dosage={medication['dosageInstruction']}
                  adrDetails={
                    medication['adrLabDetails']
                      ? medication['adrLabDetails']
                      : []
                  }
                  highlight={medication['highlight']}
                  monographReference={monographReference}
                  openDdiTab={openDdiTab}
                  openDdiMonographContent={openDdiMonographContent}
                />
              );
            }
          )
        ) : (
          <DrugDrugInteractionByType
            DrugDrugInteractionData={
              monographData[params.id].drug_drug_interaction.by_type
            }
            openDdiTab={openDdiTab}
            openDdiMonographContent={openDdiMonographContent}
          />
        )
      ) : MonographType === 'ADR' ? (
        alertType === 'medication' ? (
          patientdata[params.id].medication_with_adrs.map((medication: any) => {
            return (
              <SmartADRElement
                key={medication['id']}
                name={medication['name']}
                dosage={medication['dosageInstruction']}
                adrDetails={
                  medication['adrLabDetails'] ? medication['adrLabDetails'] : []
                }
                highlight={medication['highlight']}
                monographReference={monographReference}
                openSmartADrMonographContent={openSmartADrMonographContent}
                openSmartAdrTab={openSmartAdrTab}
              />
            );
          })
        ) : alertType === 'condition' ? (
          <SmartAdrByCondition
            smartAdrData={monographData[params.id].smart_adr.by_condition}
            openSmartAdrTab={openSmartAdrTab}
          />
        ) : (
          <SmartAdrByTpe
            smartAdrData={monographData[params.id].smart_adr.by_frequency}
            openSmartAdrTab={openSmartAdrTab}
          />
        )
      ) : alertType === 'medication' ? (
        monographData[params.id].contraindication.by_medication.map(
          (medication: any) => {
            return (
              <ContraIndicationMedElement
                key={medication['id']}
                name={medication['name']}
                dosage={medication['dosageInstruction']}
                adrDetails={
                  medication['adrLabDetails'] ? medication['adrLabDetails'] : []
                }
                beers={medication['beers']}
                highlight={'white'}
                monographReference={monographReference}
              />
            );
          }
        )
      ) : (
        <ContraindicationByTpe
          contraindicationData={
            monographData[params.id].contraindication.by_type
          }
        />
      )}
    </>
  );
};

export default MonographCard;
