import '../tab/tab.css';

import {
  Accordion,
  AccordionContent,
  AccordionIconAlignment,
  AccordionSection,
} from '../../accordion/accordion.style';
import {
  AdrData,
  SmartADRMedElementProps,
} from '../../../types/smart-adr.types';
import {
  AdrDetails,
  AdrNameAndSeverity,
  CheckBox,
  FlexContainer,
  MedElementContainer,
  MedOverlay,
} from './med-element.style';
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import HorizontalLine from '../../atoms/horizontal-line';
import SmartADRModal from '../modal/monograph-modal';
import TextSpan from '../../atoms/text-span';
import checkBox from '../constants/svg/checkBox.svg';
import commonSvg from '../constants/svg/common.svg';
import { drugDrugInteractionTabs } from '../constants/drug-drug-interaction';
import greenCheckBox from '../constants/svg/greenCheckBox.svg';
import infrequentSvg from '../constants/svg/infrequent.svg';
import majorSvg from '../constants/svg/major.svg';
import orangeCheckBox from '../constants/svg/orangeSvg.svg';
import rareSvg from '../constants/svg/rare.svg';
import redCheckBox from '../constants/svg/redCheckBox.svg';
import yellowCheckBox from '../constants/svg//yellowCheckBox.svg';

const DrugDrugInterationMedElement: React.FC<SmartADRMedElementProps> = ({
  name,
  dosage,
  adrDetails,
  highlight,
  monographReference,
  openDdiMonographContent,
  openDdiTab,
}) => {
  const [activeTab, setActiveTab] = useState<string>('SmartADR');
  const [selectedAdr, setSelectedAdr] = useState<AdrData>({
    id: '',
    adrName: '',
  });
  const [adrObject, setAdrObject] = useState<any[] | null>(null);
  const [selctedAdrFrequency, setSelectedAdrFrequency] = useState<string>('');
  useEffect(() => {
    if (openDdiTab && name === 'amiodarone (PACERONE) tablet') {
      setActiveTab('3');
      setAdrObject(adrDetails.major);
      setSelectedAdrFrequency('major');
    } else {
      setActiveTab('');
      setAdrObject(null);
      setSelectedAdrFrequency('');
    }
  }, [openDdiTab]);
  useEffect(() => {
    if (openDdiMonographContent && name === 'amiodarone (PACERONE) tablet') {
      setActiveTab('3');
      setAdrObject(adrDetails.major);
      setSelectedAdrFrequency('major');
      if (adrDetails.major) {
        setSelectedAdr({
          id: '0',
          adrName: Object.keys(adrDetails.major[0])[0],
        });
      }
    } else {
      setSelectedAdr({
        id: '',
        adrName: '',
      });
    }
  }, [openDdiMonographContent]);
  const TabTitle = ({ title, key }: { title: string; key: any }) => {
    let adrLength = 0;
    if (title === 'Minor' && adrDetails.minor) {
      adrLength = adrDetails.minor.length;
    }
    if (title === 'Moderate' && adrDetails.moderate) {
      adrLength = adrDetails.moderate.length;
    }
    if (title === 'Major' && adrDetails.major) {
      adrLength = adrDetails.major.length;
    }
    if (title === 'Severe' && adrDetails.severe) {
      adrLength = adrDetails.severe.length;
    }
    return (
      <FlexContainer>
        <img
          src={
            title === 'Severe'
              ? commonSvg
              : title === 'Moderate'
              ? infrequentSvg
              : title === 'Major'
              ? majorSvg
              : rareSvg
          }
          alt=''
        />

        <span>{adrLength}</span>
        <span>{title === 'Severe' ? 'Contraindicated' : title}</span>
        {(activeTab === '1' && title === 'Minor') ||
        (activeTab === '2' && title === 'Moderate') ||
        (activeTab === '3' && title === 'Major') ||
        (activeTab === '4' && title === 'Severe') ? (
          <MdOutlineArrowDropUp color={'black'} size={20} />
        ) : (
          <MdOutlineArrowDropDown color={'black'} size={20} />
        )}
      </FlexContainer>
    );
  };

  const handleTabSelect = (key: any) => {
    if (activeTab === key) {
      setSelectedAdr({
        id: '',
        adrName: '',
      });
      setActiveTab('smart adr');
    } else {
      if (key === '1') {
        setAdrObject(adrDetails.minor);
        setSelectedAdrFrequency('minor');
      }
      if (key === '2') {
        setAdrObject(adrDetails.moderate);
        setSelectedAdrFrequency('moderate');
      }
      if (key === '3') {
        setAdrObject(adrDetails.major);
        setSelectedAdrFrequency('major');
      }
      if (key === '4') {
        setAdrObject(adrDetails.severe);
        setSelectedAdrFrequency('severe');
      }
      setActiveTab(key);
      setSelectedAdr({
        id: '',
        adrName: '',
      });
    }
  };

  return (
    <AccordionSection>
      <Accordion>
        <MedElementContainer>
          <TextSpan weight='bold'>{name}</TextSpan>
          <AccordionIconAlignment>
            <TextSpan color='secondary' padding='0.5rem 0rem' weight='bold'>
              {dosage}
            </TextSpan>
          </AccordionIconAlignment>

          <div
            style={{
              background: `linear-gradient(to right, ${
                highlight === 'red'
                  ? 'rgba(255, 237, 237, 1)'
                  : highlight === 'yellow'
                  ? 'rgba(254, 255, 237, 1)'
                  : highlight === 'green'
                  ? 'rgba(237, 255, 240, 1)'
                  : highlight === 'orange'
                  ? '#FFF2D9'
                  : ''
              } 91%, transparent 50%)`,
              zIndex: '100',
              width: '96%',
            }}
          >
            <div id='DDI-by-medication'>
              <Tabs
                activeKey={activeTab}
                onSelect={handleTabSelect}
                id='uncontrolled-tab-example'
                color={highlight}
              >
                {drugDrugInteractionTabs.map(
                  (frequency: any, indexTab: any) => (
                    <Tab
                      className='tab-red'
                      eventKey={indexTab + 1}
                      title={
                        <TabTitle title={frequency.Title} key={indexTab + 1} />
                      }
                      disabled={
                        indexTab === 0
                          ? adrDetails.minor
                            ? false
                            : true
                          : indexTab === 1
                          ? adrDetails.moderate
                            ? false
                            : true
                          : indexTab === 2
                          ? adrDetails.major
                            ? false
                            : true
                          : indexTab === 3
                          ? adrDetails.severe
                            ? false
                            : true
                          : false // Provide a boolean value when none of the conditions match
                      }
                    >
                      {adrObject &&
                        adrObject.map((adrDetail: any, index: any) => (
                          <div>
                            <div
                              onClick={() => {
                                if (selectedAdr.id === index) {
                                  setSelectedAdr({
                                    id: '',
                                    adrName: '',
                                  });
                                } else {
                                  setSelectedAdr({
                                    id: index,
                                    adrName: Object.keys(adrDetail)[0],
                                  });
                                }
                              }}
                            >
                              <AdrDetails
                                border={
                                  highlight === 'red'
                                    ? '1px solid #FFEDED'
                                    : highlight === 'yellow'
                                    ? '1px solid #FEFFED'
                                    : highlight === 'green'
                                    ? '1px solid #EDFFF0'
                                    : highlight === 'orange'
                                    ? '1px solid rgba(255, 233, 191, 0.3)'
                                    : ''
                                }
                                hoverBackground={
                                  selectedAdr.adrName ===
                                  Object.keys(adrDetail)[0]
                                    ? frequency.Background
                                    : frequency.HoverBackground
                                }
                                background={
                                  selectedAdr.adrName ===
                                  Object.keys(adrDetail)[0]
                                    ? frequency.Background
                                    : ' #ffffff'
                                }
                              >
                                <CheckBox>
                                  {selectedAdr.adrName ===
                                  Object.keys(adrDetail)[0] ? (
                                    <img
                                      style={{ marginBottom: '6px' }}
                                      src={
                                        activeTab === '1'
                                          ? greenCheckBox
                                          : activeTab === '2'
                                          ? yellowCheckBox
                                          : activeTab === '3'
                                          ? orangeCheckBox
                                          : redCheckBox
                                      }
                                      alt=''
                                    />
                                  ) : (
                                    <img
                                      style={{ marginBottom: '6px' }}
                                      src={checkBox}
                                      alt=''
                                    />
                                  )}
                                </CheckBox>
                                <AdrNameAndSeverity>
                                  {Object.keys(adrDetail)[0]}
                                </AdrNameAndSeverity>
                                <AdrNameAndSeverity>
                                  {
                                    adrDetail[Object.keys(adrDetail)[0]][0]
                                      .severity
                                  }
                                </AdrNameAndSeverity>
                              </AdrDetails>
                            </div>
                            {selectedAdr.adrName &&
                              parseInt(selectedAdr.id) === index && (
                                <AccordionContent
                                  onClick={(event) => event.stopPropagation()}
                                >
                                  <SmartADRModal
                                    adrDetails={
                                      adrDetails[selctedAdrFrequency][
                                        parseInt(selectedAdr.id)
                                      ][selectedAdr.adrName]
                                    }
                                    monographReference={monographReference}
                                    monographType='drug-drug-interaction'
                                  />

                                  <HorizontalLine
                                    width='100%'
                                    opacity='0.35'
                                    margin='0.5rem 0rem 0rem 0rem'
                                  />
                                </AccordionContent>
                              )}
                          </div>
                        ))}
                    </Tab>
                  )
                )}
              </Tabs>
            </div>
          </div>

          <HorizontalLine
            width='100%'
            opacity='0.25'
            margin='0.5rem 0rem 0rem 0rem'
          />
          {highlight && <MedOverlay highlight={highlight}></MedOverlay>}
          {/* {selectedAdr.adrName && (
            <AccordionContent onClick={(event) => event.stopPropagation()}>
              <SmartADRModal
                adrDetails={
                  adrDetails[selctedAdrFrequency][selectedAdr.id][
                    selectedAdr.adrName
                  ]
                }
                monographReference={monographReference}
                monographType='drug-drug-interaction'
              />

              <HorizontalLine
                width='100%'
                opacity='0.35'
                margin='0.5rem 0rem 0rem 0rem'
              />
            </AccordionContent>
          )} */}
        </MedElementContainer>
      </Accordion>
    </AccordionSection>
  );
};

export default DrugDrugInterationMedElement;
