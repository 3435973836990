import {
  AdrLabDetails,
  AdrName,
  ColumnContainer,
  MedElementContainer,
  MedOverlay,
  Text,
} from './med-check-element.style';

import HorizontalLine from '../../atoms/horizontal-line';
import { MedCheckElementProps } from '../../../types/smart-adr.types';
import TextSpan from '../../atoms/text-span';

const MedECheckelement: React.FC<MedCheckElementProps> = ({
  name,
  color,
  labs,
  severity
}) => {
  return (
    <MedElementContainer>
      <AdrName>
        <TextSpan weight='bold'>{name}</TextSpan>
        <TextSpan color='secondary' weight='bold'>
          {severity}
        </TextSpan>
      </AdrName>
      <ColumnContainer>
        {labs.map((lab) => (
          <AdrLabDetails highlight={color}>
            <Text style={{ color: 'black', fontWeight: '500' }}>
              {lab.name}
            </Text>
            <Text
              style={{
                color: 'black',
                padding: '0.5rem 0rem',
                fontWeight: '400',
              }}
            >
              {lab.date}
            </Text>
          </AdrLabDetails>
        ))}
      </ColumnContainer>
      {/* <TextSpan color='secondary' padding='0.5rem 0rem' weight='bold'>
        {labs.join(' | ')}
      </TextSpan> */}

      <HorizontalLine
        width='682px'
        opacity='0.25'
        margin='0.5rem 0rem 0rem 0rem'
      />
      {color && <MedOverlay highlight={color}></MedOverlay>}
    </MedElementContainer>
  );
};

export default MedECheckelement;
