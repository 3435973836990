import { CardContainer, CardPill } from './readings-card.style';

import { ImRedo2 } from 'react-icons/im';
import React from 'react';
import { ReadingsCardProps } from '../../../types/styles.types';
import TextSpan from '../text-span';

const ReadingsCard: React.FC<ReadingsCardProps> = ({
  title,
  color,
  children,
  icon,
  id,
}) => {
  return (
    <CardContainer
      style={{
        width:
          title === 'BMP/CBC/INR'
            ? '450px'
            : title === 'Vitals'
            ? '350px'
            : title === 'Medical Problems'
            ? '35px'
            : '700px',
      }}
      color={color}
      id={id}
    >
      <div
        id='smart-pharm-icon'
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <CardPill color={color}>
          {icon}
          <TextSpan color={color} weight='bold' padding='0rem 0.5rem'>
            {title}
          </TextSpan>
          <ImRedo2 />
        </CardPill>{' '}
      </div>
      {children}
    </CardContainer>
  );
};

export default ReadingsCard;
