import 'bootstrap/dist/css/bootstrap.min.css';
import '../tab/tab.css';

import {
  AdrDetails,
  AdrNameAndSeverity,
  CheckBox,
  TabContainer,
  TabHeading,
} from '../by-type/by-type.style';
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md';
import React, { useEffect, useState } from 'react';

import { AccordionContent } from '../../accordion/accordion.style';
import { AdrData } from '../../../types/smart-adr.types';
import HorizontalLine from '../../atoms/horizontal-line';
import SmartADRModal from '../modal/monograph-modal';
import checkBox from '../constants/svg/checkBox.svg';
import greenCheckBox from '../constants/svg/greenCheckBox.svg';
import redCheckBox from '../constants/svg/redCheckBox.svg';
import yellowCheckBox from '../constants/svg//yellowCheckBox.svg';

const SmartAdrByCondition: React.FC<any> = ({
  smartAdrData,
  openSmartAdrTab,
}) => {
  const [selectedAdr, setSelectedAdr] = useState<AdrData>({
    id: '',
    adrName: '',
  });
  const [activeTab, setActiveTab] = useState<string>('');

  useEffect(() => {
    if (openSmartAdrTab) {
      setActiveTab('Bradycardia');
    } else {
      setActiveTab('');
    }
  }, [openSmartAdrTab]);

  return (
    <>
      {smartAdrData.map((adrData: any) => {
        return (
          <div id={`${adrData.adrName}-by-condition`}>
            <TabContainer
              style={{
                pointerEvents:
                  adrData.adrDetails.length === 0 ? 'none' : 'auto',
              }}
              background={
                adrData.adrDetails[0].adrLabDetails[0].frequency === 'common'
                  ? '#FFC7C7'
                  : adrData.adrDetails[0].adrLabDetails[0].frequency ===
                    'infrequent'
                  ? '#FCFFC7'
                  : adrData.adrDetails[0].adrLabDetails[0].frequency === 'rare'
                  ? '#C7FFD0'
                  : ''
              }
              // border='#909090'
              onClick={() => {
                if (activeTab === adrData.adrName) {
                  setActiveTab('');
                } else {
                  setActiveTab(adrData.adrName);
                }
                setSelectedAdr({
                  id: '',
                  adrName: '',
                });
              }}
            >
              <TabHeading>
                {' '}
                {adrData.adrName} (
                <span
                  style={{
                    color: adrData.adrDetails.length > 0 ? 'red' : '',
                  }}
                >
                  {adrData.adrDetails.length}
                </span>
                )
              </TabHeading>
              {activeTab === adrData.adrName ? (
                <MdOutlineArrowDropUp color={'black'} size={20} />
              ) : (
                <MdOutlineArrowDropDown color={'black'} size={20} />
              )}
            </TabContainer>

            {activeTab === adrData.adrName &&
              adrData.adrDetails.map((adrDetail: any, index: any) => (
                <div>
                  <div
                    onClick={() => {
                      if (selectedAdr.id === index) {
                        setSelectedAdr({
                          id: '',
                          adrName: '',
                        });
                      } else {
                        setSelectedAdr({
                          id: index,
                          adrName: Object.keys(adrDetail)[0],
                        });
                      }
                    }}
                  >
                    {adrDetail.adrLabDetails[0].frequency === 'common' ? (
                      <AdrDetails
                        border={'1px solid #ffffff'}
                        hoverBackground={
                          selectedAdr.id === index ? '#FFC7C7' : '#ffffff'
                        }
                        background={
                          selectedAdr.id === index ? '#FFC7C7' : ' #ffeded'
                        }
                      >
                        <CheckBox>
                          {selectedAdr.id === index ? (
                            <img
                              style={{ marginBottom: '6px' }}
                              src={redCheckBox}
                              alt=''
                            />
                          ) : (
                            <img
                              style={{ marginBottom: '6px' }}
                              src={checkBox}
                              alt=''
                            />
                          )}
                        </CheckBox>
                        <AdrNameAndSeverity>
                          {adrDetail.medicationName}
                        </AdrNameAndSeverity>
                      </AdrDetails>
                    ) : adrDetail.adrLabDetails[0].frequency ===
                      'infrequent' ? (
                      <AdrDetails
                        border={'1px solid #ffffff'}
                        hoverBackground={
                          selectedAdr.id === index ? '#FCFFC7' : '#ffffff'
                        }
                        background={
                          selectedAdr.id === index ? '#FCFFC7' : ' #feffed'
                        }
                      >
                        <CheckBox>
                          {selectedAdr.id === index ? (
                            <img
                              style={{ marginBottom: '6px' }}
                              src={yellowCheckBox}
                              alt=''
                            />
                          ) : (
                            <img
                              style={{ marginBottom: '6px' }}
                              src={checkBox}
                              alt=''
                            />
                          )}
                        </CheckBox>
                        <AdrNameAndSeverity>
                          {adrDetail.medicationName}
                        </AdrNameAndSeverity>
                      </AdrDetails>
                    ) : (
                      <AdrDetails
                        border={'1px solid #ffffff'}
                        hoverBackground={
                          selectedAdr.id === index ? '#C7FFD0' : '#ffffff'
                        }
                        background={
                          selectedAdr.id === index ? '#C7FFD0' : ' #edfff0'
                        }
                      >
                        <CheckBox>
                          {selectedAdr.id === index ? (
                            <img
                              style={{ marginBottom: '6px' }}
                              src={greenCheckBox}
                              alt=''
                            />
                          ) : (
                            <img
                              style={{ marginBottom: '6px' }}
                              src={checkBox}
                              alt=''
                            />
                          )}
                        </CheckBox>
                        <AdrNameAndSeverity>
                          {adrDetail.medicationName}
                        </AdrNameAndSeverity>
                      </AdrDetails>
                    )}
                  </div>
                  {activeTab === adrData.adrName &&
                    selectedAdr.id !== '' &&
                    selectedAdr.id === index && (
                      <AccordionContent
                        onClick={(event) => event.stopPropagation()}
                      >
                        <SmartADRModal
                          adrDetails={
                            adrData['adrDetails'][selectedAdr.id].adrLabDetails
                          }
                          monographReference={{}}
                        />

                        <HorizontalLine
                          width='100%'
                          opacity='0.35'
                          margin='0.5rem 0rem 0rem 0rem'
                        />
                      </AccordionContent>
                    )}
                </div>
              ))}
            {/* {activeTab === adrData.adrName && selectedAdr.id !== '' && (
              <AccordionContent onClick={(event) => event.stopPropagation()}>
                <SmartADRModal
                  adrDetails={
                    adrData['adrDetails'][selectedAdr.id].adrLabDetails
                  }
                  monographReference={{}}
                />

                <HorizontalLine
                  width='100%'
                  opacity='0.35'
                  margin='0.5rem 0rem 0rem 0rem'
                />
              </AccordionContent>
            )} */}
          </div>
        );
      })}
    </>
  );
};

export default SmartAdrByCondition;
