import { FaHeartbeat } from 'react-icons/fa';
// import { FhirClientContext } from '../../contexts/fhir-client-context';
import ReadingsCard from '../atoms/readings-card/readings-card';
import TextSpan from '../atoms/text-span';
import { UserInfoDiv } from '../layout/user-profile/ user-profile.style';
import { VitalsElementsContainer } from './vitals-card.style';
import { patientdata } from '../../../src/utils/patients-data/patient-data';
import theme from '../../global/theme/theme';
import { useParams } from 'react-router-dom';

const VitalsCard = () => {
  const { id } = useParams();

  return (
    <ReadingsCard
      color={theme.vitalsGreen}
      title='Vitals'
      icon={<FaHeartbeat />}
      id='vitals-card'
    >
      <VitalsElementsContainer>
        <UserInfoDiv>
          <TextSpan color='secondary' weight='600'>
            Body Temperature:&nbsp;
          </TextSpan>
          <TextSpan weight='bold'>
            {patientdata[id].vitals.Body_Temperature}
          </TextSpan>
        </UserInfoDiv>
        <UserInfoDiv>
          <TextSpan color='secondary' weight='600'>
            Body Weight:&nbsp;
          </TextSpan>
          <TextSpan weight='bold'>
            {patientdata[id].vitals.Body_Weight}
          </TextSpan>
        </UserInfoDiv>

        <UserInfoDiv>
          <TextSpan color='secondary' weight='600'>
            Heart Rate:&nbsp;
          </TextSpan>
          <TextSpan weight='bold'>
            {patientdata[id].vitals.Heart_Pulse_Rate}
          </TextSpan>
        </UserInfoDiv>

        <UserInfoDiv>
          <TextSpan color='secondary' weight='600'>
            Respiratory Rate:&nbsp;
          </TextSpan>
          <TextSpan weight='bold'>
            {patientdata[id].vitals.Respiratory_Rate}
          </TextSpan>
        </UserInfoDiv>

        <UserInfoDiv>
          <TextSpan color='secondary' weight='600'>
            O2 Sat:&nbsp;
          </TextSpan>
          <TextSpan weight='bold'>
            {patientdata[id].vitals.Oxygen_Saturation}
          </TextSpan>
        </UserInfoDiv>
      </VitalsElementsContainer>
    </ReadingsCard>
  );
};

export default VitalsCard;
