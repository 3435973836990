import 'bootstrap/dist/css/bootstrap.min.css';
import './date-picker-react/date-picker-react.css';
import './auto-suggest/auto-suggest.css';
import {
  AdrFrequencyAlignment,
  SearchResultText,
  DescriptionText,
  ApplyFilterButton,
} from './med-check-element/med-check-element.style';
import AutoSuggest from 'react-autosuggest';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import BasicSpinner from '../atoms/basic-spinner';
import DatePicker from 'react-datepicker';
import { Form } from 'react-bootstrap';
import HttpConstants from '../../constants/http.constants';
import MedECheckelement from './med-check-element/med-check-element';
import UrlConstants from '../../constants/url.constants';
import { makeAuthenticatedApiCall } from '../../services/api.services';
import React from 'react';
import { MedCheck } from '../../types/smart-adr.types';

const MEdCheck :React.FC<MedCheck> = ({ dateFilter }) => {
  // const [value, setValue] = useState('');
  const [error, setError] = useState<string | null | undefined>('');
  const [loading, setLoading] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [suggestionLoading, setSuggestionLoading] = useState<boolean>(false);
  const [medCheckAdrs, setMedCheckAdrs] = useState([]);
  const [dateRange, setDateRange] = useState<any>([null, null]);
  const [startDate, endDate] = dateRange;
  const [productId, setProductId] = useState<string>('');
  // const [suggestions, setSuggestions] = useState([]);
  const maxDate = new Date();
  const [value, setValue] = useState('');
  const [valueSelected, setValueSelected] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<any[]>([]);

  async function getSuggestions(value: string): Promise<any[]> {
    setSuggestionLoading(true);
    const medicationDetails = await makeAuthenticatedApiCall(
      UrlConstants.GET_MEDICATION_NAME_AND_PRODUCT_ID.USECASE,
      HttpConstants.GET,
      {
        'medication-name': value,
      }
    );
    setSuggestionLoading(false);
    return medicationDetails.data.data;
  }

  const onSuggestionSelected = (
    _: any,
    { suggestion }: { suggestion: any }
  ) => {
    setProductId(suggestion.productID);
    setValueSelected(true);
  };

  const shouldRenderWhat = (
    suggestions: any[],
    value: string,
    children: React.ReactNode
    
  ) => {
    const commonStyle = { width: dateFilter ? '700px' : '593px' }
    if (suggestions.length === 0 && value === '') {
      return <div  style={commonStyle}className='example_suggest'>Enter a medication name</div>;
    } else if (value.length <= 2)
      return <div style={commonStyle} className='example_suggest'>Enter at least 3 letters</div>;
    else if (suggestionLoading === true)
      return <div style={commonStyle} className='example_suggest'>Searching ...</div>;
    else if (
      suggestions.length === 0 &&
      value !== '' &&
      valueSelected !== true &&
      suggestionLoading === false
    ) {
      return (
        <div  style={commonStyle} className='example_suggest'>No matching medication found</div>
      );
    } else {
      if (children !== null) {
        return children;
      }
    }
  };


  useEffect(() => {
    if (dateFilter === false) {
      setDateRange([null, null]);
    } 
  }, [dateFilter]);


  const applyFilters = async () => {
    try{
    if (!loading) {
      setLoading(true);

      const adrDetails = await makeAuthenticatedApiCall(
        UrlConstants.GET_MED_CHECK_ADR_DETAILS.USECASE,
        HttpConstants.GET,
        {
          rxcui: productId,
          'from-date': startDate,
          'to-date': endDate,
        }
      );

      setMedCheckAdrs(adrDetails.data.data);
      if (isEmpty(adrDetails.data.data)) {
        setError('No Matching ADRs Found.');
      } else {
        setError(null);
      }

      setLoading(false);
    }
  }
  catch (error :any) {
    if(error.response.data.error.exceptionName==='ExternalResourceFailedException'){
      setError('External systems are down, please refresh')
    }
    else{
    setError('Something went wrong. Please relaunch the app');
    }
    setLoading(false);
  }
  };

  const renderSuggestionsContainer = ({
    containerProps,
    children,
    query,
  }: any): any => {
    return (
      <div {...containerProps}>
        {shouldRenderWhat(suggestions, value, children)}
      </div>
    );
  };

  const CustomDatePickerInput = React.forwardRef<
    HTMLInputElement,
    React.InputHTMLAttributes<HTMLInputElement>
  >((props, ref) => (
    <input
      {...props}
      ref={ref}
      style={{ cursor: loading ? 'not-allowed' : 'pointer' }}
    />
  ));

  return (
    <div style={{ height: '450px', position: 'relative' }}>

      <Form.Group>
        <AdrFrequencyAlignment>
          <AutoSuggest 
          
            suggestions={suggestions}
            onSuggestionsClearRequested={() => setSuggestions([])}
            onSuggestionsFetchRequested={async ({ value }) => {
              setValueSelected(false);
              setValue(value);
              if (value.length > 2) {
                setSuggestions(await getSuggestions(value));
              }
            }}
            onSuggestionSelected={onSuggestionSelected}
            getSuggestionValue={(suggestion) => suggestion.ProductNameLong}
            renderSuggestion={(suggestion) => (
              <span>{suggestion.ProductNameLong}</span>
            )}
            renderSuggestionsContainer={renderSuggestionsContainer}
            inputProps={{
              style:{width:dateFilter?'49vw':''},
              placeholder: 'Search medication by name...',
              value: value,
              onChange: (_, { newValue }) => {
                setValue(newValue);
              },
             
            }}
            highlightFirstSuggestion={true}
        
          />
          {!dateFilter && 
          <ApplyFilterButton
            style={{ cursor: loading ? 'not-allowed' : 'pointer' }}
            onClick={applyFilters}
          >
            {' '}
            Apply
          </ApplyFilterButton> 
 }
        
        </AdrFrequencyAlignment>
        <AdrFrequencyAlignment>
          {dateFilter ? (
            <DatePicker 
              disabled={loading}
              className='custom-datepicker-med'
              selectsRange={true}
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              onChange={(update: any) => {
                setDateRange(update);
              }}
              customInput={<CustomDatePickerInput />}
              maxDate={maxDate}
              isClearable={true}
              placeholderText=' Start date - End date'
            />
          ) : (
            ''
          )}
          {dateFilter &&
          <ApplyFilterButton
            style={{ cursor: loading ? 'not-allowed' : 'pointer' }}
            onClick={applyFilters}
          >
            {' '}
            Apply
          </ApplyFilterButton>
}
        </AdrFrequencyAlignment>
      </Form.Group>
      {loading ? (
        <BasicSpinner label='Loading ADR Results...' padding='4rem 0' />
      ) : error ? (
        <SearchResultText style={{ textAlign: 'center' }}>
          {error}
        </SearchResultText>
      ) : (
        <>
          {!isEmpty(medCheckAdrs) ? (
            <SearchResultText>Search Results:</SearchResultText>
          ) : (
            ''
          )}
          {medCheckAdrs.map((adrDetails) => (
            <MedECheckelement
              name={adrDetails['adr']}
              color={adrDetails['highlight']}
              labs={adrDetails['labs']}
              severity={adrDetails['severity']}
            />
          ))}
        </>
      )}
      {!loading && isEmpty(medCheckAdrs) && (
        <DescriptionText>
          Please provide the name of the medication for the assessment of its
          impact on any existing adverse drug reactions (ADRs) in the patient.
          If you would like to determine the effects of medications on ADRs or
          labs within a specific date range, kindly specify your desired date
          range.
        </DescriptionText>
      )}
    </div>
  );
};

export default MEdCheck;
