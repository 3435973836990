import React from 'react';
import { TextSpanProps } from '../../types/styles.types';
import styled from 'styled-components';

const TextSpanElement = styled.span<TextSpanProps>`
  color: ${(props) =>
    props.overwriteColor
      ? props.overwriteColor
      : props.theme.text[props.color || 'primary']};
  font-weight: ${(props) => props.weight || '400'};
  padding: ${(props) => props.padding || '0'};
  font-size: ${(props) => props.font || '0'};
`;

const TextSpan: React.FC<TextSpanProps> = ({
  overwriteColor,
  color = 'primary',
  weight = '400',
  padding = '0',
  font='16px',
  children,

}) => {
  return (
    <TextSpanElement
      overwriteColor={overwriteColor}
      color={color}
      weight={weight}
      padding={padding}
      font={font}
      dangerouslySetInnerHTML={{ __html: children ? String(children) : '' }}
    />
  );
};

export default TextSpan;
