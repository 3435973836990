import 'bootstrap/dist/css/bootstrap.min.css';
import '../tab/tab.css';

import {
  AdrDetails,
  AdrNameAndSeverity,
  CheckBox,
  TabContainer,
  TabHeading,
} from './by-type.style';
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md';
import React, { useEffect, useState } from 'react';

import { AccordionContent } from '../../accordion/accordion.style';
import { AdrData } from '../../../types/smart-adr.types';
import HorizontalLine from '../../atoms/horizontal-line';
import SmartADRModal from '../modal/monograph-modal';
import checkBox from '../constants/svg/checkBox.svg';
import greenCheckBox from '../constants/svg/greenCheckBox.svg';
import redCheckBox from '../constants/svg/redCheckBox.svg';
import yellowCheckBox from '../constants/svg//yellowCheckBox.svg';

const SmartAdrByTpe: React.FC<any> = ({ smartAdrData, openSmartAdrTab }) => {
  const [selectedAdr, setSelectedAdr] = useState<AdrData>({
    id: '',
    adrName: '',
  });
  const [activeTab, setActiveTab] = useState<string>('');
  useEffect(() => {
    if (openSmartAdrTab) setActiveTab('Infrequent');
    else setActiveTab('');
  }, [openSmartAdrTab]);
  return (
    <div id='smart-adr-by-type'>
      <TabContainer
        style={{
          pointerEvents: smartAdrData.common.length === 0 ? 'none' : 'auto',
        }}
        background='#FFEDED'
        border='#EDBBBB'
        onClick={() => {
          if (activeTab === 'Common') {
            setActiveTab('');
          } else {
            setActiveTab('Common');
          }
          setSelectedAdr({
            id: '',
            adrName: '',
          });
        }}
      >
        <TabHeading>
          {' '}
          Common (
          <span
            style={{
              color: smartAdrData.common.length > 0 ? 'red' : '',
            }}
          >
            {smartAdrData.common.length}
          </span>
          )
        </TabHeading>
        {activeTab === 'Common' ? (
          <MdOutlineArrowDropUp color={'black'} size={20} />
        ) : (
          <MdOutlineArrowDropDown color={'black'} size={20} />
        )}
      </TabContainer>

      {activeTab === 'Common' &&
        smartAdrData.common.map((adrDetail: any, index: any) => (
          <div>
            <div
              onClick={() => {
                if (selectedAdr.id === index) {
                  setSelectedAdr({
                    id: '',
                    adrName: '',
                  });
                } else {
                  setSelectedAdr({
                    id: index,
                    adrName: Object.keys(adrDetail)[0],
                  });
                }
              }}
            >
              <AdrDetails
                border={'1px solid #FFEDED'}
                hoverBackground={
                  selectedAdr.id === index ? '#FFC7C7' : '#ffeded'
                }
                background={selectedAdr.id === index ? '#FFC7C7' : ' #ffffff'}
              >
                <CheckBox>
                  {selectedAdr.id === index ? (
                    <img
                      style={{ marginBottom: '6px' }}
                      src={redCheckBox}
                      alt=''
                    />
                  ) : (
                    <img
                      style={{ marginBottom: '6px' }}
                      src={checkBox}
                      alt=''
                    />
                  )}
                </CheckBox>
                <AdrNameAndSeverity>
                  {adrDetail.medicationName}
                </AdrNameAndSeverity>
              </AdrDetails>
            </div>
            {activeTab === 'Common' &&
              selectedAdr.id !== '' &&
              selectedAdr.id === index && (
                <AccordionContent onClick={(event) => event.stopPropagation()}>
                  <SmartADRModal
                    adrDetails={
                      smartAdrData['common'][selectedAdr.id].adrLabDetails
                    }
                    monographReference={{}}
                  />

                  <HorizontalLine
                    width='100%'
                    opacity='0.35'
                    margin='0.5rem 0rem 0rem 0rem'
                  />
                </AccordionContent>
              )}
          </div>
        ))}
      {/* {activeTab === 'Common' && selectedAdr.id !== '' && (
        <AccordionContent onClick={(event) => event.stopPropagation()}>
          <SmartADRModal
            adrDetails={smartAdrData['common'][selectedAdr.id].adrLabDetails}
            monographReference={{}}
          />

          <HorizontalLine
            width='100%'
            opacity='0.35'
            margin='0.5rem 0rem 0rem 0rem'
          />
        </AccordionContent>
      )} */}
      <div id='smart-adr-by-infrequent-frequency'>
        <TabContainer
          style={{
            pointerEvents:
              smartAdrData.infrequent.length === 0 ? 'none' : 'auto',
          }}
          background='#FDFFD9'
          border='#EAEDBB'
          onClick={() => {
            if (activeTab === 'Infrequent') {
              setActiveTab('');
            } else {
              setActiveTab('Infrequent');
            }
            setSelectedAdr({
              id: '',
              adrName: '',
            });
          }}
        >
          <TabHeading>
            {' '}
            Infrequent (
            <span
              style={{
                color: smartAdrData.infrequent.length > 0 ? 'red' : '',
              }}
            >
              {smartAdrData.infrequent.length}
            </span>
            )
          </TabHeading>
          {activeTab === 'Infrequent' ? (
            <MdOutlineArrowDropUp color={'black'} size={20} />
          ) : (
            <MdOutlineArrowDropDown color={'black'} size={20} />
          )}
        </TabContainer>
        {activeTab === 'Infrequent' &&
          smartAdrData.infrequent.map((adrDetail: any, index: any) => (
            <div>
              <div
                onClick={() => {
                  if (selectedAdr.id === index) {
                    setSelectedAdr({
                      id: '',
                      adrName: '',
                    });
                  } else {
                    setSelectedAdr({
                      id: index,
                      adrName: Object.keys(adrDetail)[0],
                    });
                  }
                }}
              >
                <AdrDetails
                  border={'1px solid #FFEDED'}
                  hoverBackground={
                    selectedAdr.id === index ? '#FCFFC7' : '#feffed'
                  }
                  background={selectedAdr.id === index ? '#FCFFC7' : ' #ffffff'}
                >
                  <CheckBox>
                    {selectedAdr.id === index ? (
                      <img
                        style={{ marginBottom: '6px' }}
                        src={yellowCheckBox}
                        alt=''
                      />
                    ) : (
                      <img
                        style={{ marginBottom: '6px' }}
                        src={checkBox}
                        alt=''
                      />
                    )}
                  </CheckBox>
                  <AdrNameAndSeverity>
                    {adrDetail.medicationName}
                  </AdrNameAndSeverity>
                </AdrDetails>
              </div>
              {activeTab === 'Infrequent' &&
                selectedAdr.id !== '' &&
                selectedAdr.id === index && (
                  <AccordionContent
                    onClick={(event) => event.stopPropagation()}
                  >
                    <SmartADRModal
                      adrDetails={
                        smartAdrData['infrequent'][selectedAdr.id].adrLabDetails
                      }
                      monographReference={{}}
                    />

                    <HorizontalLine
                      width='100%'
                      opacity='0.35'
                      margin='0.5rem 0rem 0rem 0rem'
                    />
                  </AccordionContent>
                )}
            </div>
          ))}
      </div>
      {/* {activeTab === 'Infrequent' && selectedAdr.id !== '' && (
        <AccordionContent onClick={(event) => event.stopPropagation()}>
          <SmartADRModal
            adrDetails={
              smartAdrData['infrequent'][selectedAdr.id].adrLabDetails
            }
            monographReference={{}}
          />

          <HorizontalLine
            width='100%'
            opacity='0.35'
            margin='0.5rem 0rem 0rem 0rem'
          />
        </AccordionContent>
      )} */}
      <TabContainer
        style={{
          pointerEvents: smartAdrData.rare.length === 0 ? 'none' : 'auto',
        }}
        background='#D9FFDF'
        border='#BBEDC3'
        onClick={() => {
          if (activeTab === 'Rare') {
            setActiveTab('');
          } else {
            setActiveTab('Rare');
          }
          setSelectedAdr({
            id: '',
            adrName: '',
          });
        }}
      >
        <TabHeading>
          {' '}
          Rare (
          <span
            style={{
              color: smartAdrData.rare.length > 0 ? 'red' : '',
            }}
          >
            {smartAdrData.rare.length}
          </span>
          ){' '}
        </TabHeading>
        {activeTab === 'Rare' ? (
          <MdOutlineArrowDropUp color={'black'} size={20} />
        ) : (
          <MdOutlineArrowDropDown color={'black'} size={20} />
        )}
      </TabContainer>
      {activeTab === 'Rare' &&
        smartAdrData.rare.map((adrDetail: any, index: any) => (
          <div>
            <div
              onClick={() => {
                if (selectedAdr.id === index) {
                  setSelectedAdr({
                    id: '',
                    adrName: '',
                  });
                } else {
                  setSelectedAdr({
                    id: index,
                    adrName: Object.keys(adrDetail)[0],
                  });
                }
              }}
            >
              <AdrDetails
                border={'1px solid #FFEDED'}
                hoverBackground={
                  selectedAdr.id === index ? '#C7FFD0' : '#edfff0'
                }
                background={selectedAdr.id === index ? '#C7FFD0' : ' #ffffff'}
              >
                <CheckBox>
                  {selectedAdr.id === index ? (
                    <img
                      style={{ marginBottom: '6px' }}
                      src={greenCheckBox}
                      alt=''
                    />
                  ) : (
                    <img
                      style={{ marginBottom: '6px' }}
                      src={checkBox}
                      alt=''
                    />
                  )}
                </CheckBox>
                <AdrNameAndSeverity>
                  {adrDetail.medicationName}
                </AdrNameAndSeverity>
              </AdrDetails>
            </div>
            {activeTab === 'Rare' &&
              selectedAdr.id !== '' &&
              selectedAdr.id === index && (
                <AccordionContent onClick={(event) => event.stopPropagation()}>
                  <SmartADRModal
                    adrDetails={
                      smartAdrData['rare'][selectedAdr.id].adrLabDetails
                    }
                    monographReference={{}}
                  />

                  <HorizontalLine
                    width='100%'
                    opacity='0.35'
                    margin='0.5rem 0rem 0rem 0rem'
                  />
                </AccordionContent>
              )}
          </div>
        ))}
      {/* {activeTab === 'Rare' && selectedAdr.id !== '' && (
        <AccordionContent onClick={(event) => event.stopPropagation()}>
          <SmartADRModal
            adrDetails={smartAdrData['rare'][selectedAdr.id].adrLabDetails}
            monographReference={{}}
          />

          <HorizontalLine
            width='100%'
            opacity='0.35'
            margin='0.5rem 0rem 0rem 0rem'
          />
        </AccordionContent>
      )} */}
    </div>
  );
};

export default SmartAdrByTpe;
