import styled from 'styled-components';

export const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
`;

export const Accordion = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
`;

export const AccordionContent = styled.div`
  background-color: white;
  overflow: hidden;
  transition: max-height 0.6s ease;
`;

export const AccordionIconAlignment = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
`;
