import './date-picker/date-picker.css';

import {
  ApplyFilterButton,
  DaterPickerAlignment,
  FilterText,
} from './med-element/med-element.style';
import { ReferenceData, SmartADRCardError } from '../../types/smart-adr.types';
import { useEffect, useState } from 'react';

import BasicSpinner from '../atoms/basic-spinner';
import DatePicker from 'react-datepicker';
import HttpConstants from '../../constants/http.constants';
import React from 'react';
import { SessionStorageConstants } from '../../constants/session-storage.constants';
import SmartADRMedElement from './med-element/med-element';
import TextSpan from '../atoms/text-span';
import UrlConstants from '../../constants/url.constants';
import { makeAuthenticatedApiCall } from '../../services/api.services';
import { patientdata } from '../../utils/patients-data/patient-data';
import theme from '../../global/theme/theme';
import { useParams } from 'react-router-dom';

const SmartADRCard: React.FC<SmartADRCardError> = ({
  authError,
  dateFilter,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null | undefined>(authError);
  const [monographReference, setMonographReference] = useState<ReferenceData>(
    {}
  );
  const params: any = useParams();

  const [dateRange, setDateRange] = useState<any>([null, null]);
  const [startDate, endDate] = dateRange;
  const maxDate = new Date();
  // const [labObservations, setLabObservations] = useState([]);
  // const [mappingTable, setMappingTable] = useState({});
  // const [
  //   medicationRequestsWithADRDetails,
  //   setMedicationRequestsWithADRDetails,
  // ] = useState([]);

  useEffect(() => {
    setError(authError);
  }, [authError]);

  /**
   * Fetch ADRs from backend for the given medication requests
   * @param {*} medicationRequests_
   * @returns
   */
  const fetchAdrs = async () => {
    try {
      const metaDataEndpoints = sessionStorage.getItem(
        SessionStorageConstants.EPIC_METADATA
      );
      const parsedMetaDataEndpoints = metaDataEndpoints
        ? JSON.parse(metaDataEndpoints)
        : null;
      const medicationWithAdrDetails = await makeAuthenticatedApiCall(
        UrlConstants.GET_ADR.USECASE,
        HttpConstants.GET,
        {
          'bypass-tty': process.env.REACT_APP_BYPASS_TTY,
          'access-token': sessionStorage.getItem(
            SessionStorageConstants.EPIC_ACCESS_TOKEN
          ),
          'patient-id': sessionStorage.getItem(
            SessionStorageConstants.EPIC_PATIENT_ID
          ),
          audience: parsedMetaDataEndpoints['audience'],
          'from-date': startDate,
          'to-date': endDate,
        }
      );
      setMonographReference(medicationWithAdrDetails.data.data.References);
      return medicationWithAdrDetails.data.data
        .MedicationRequestsWithADRDetails;
    } catch (error) {
      throw error;
    }
  };

  /**
   * main function, which fetches lab, med request, med details
   * fetch adrs, construct mapping table and orders meds based on highlight color
   */
  const applySmartADR = async () => {
    try {
      if (
        sessionStorage.getItem(SessionStorageConstants.EPIC_ACCESS_TOKEN) &&
        sessionStorage.getItem(SessionStorageConstants.EPIC_PATIENT_ID)
      ) {
        setLoading(true);
        const medicationWithAdrDetails = await fetchAdrs().catch((err) => {
          throw err;
        });

        if (medicationWithAdrDetails.length === 0) {
          setError('No medications to apply SmartADR');
        } else {
          // setMedicationRequestsWithADRDetails(medicationWithAdrDetails);
          setError(null);
        }
        setLoading(false);
      }
    } catch (error: any) {
      if (
        error.response.data.error.exceptionName ===
        'ExternalResourceFailedException'
      ) {
        setError('External systems are down, please refresh');
      } else {
        setError('Something went wrong. Please relaunch the app');
      }
      setLoading(false);
    }
  };
  const CustomDatePickerInput = React.forwardRef<
    HTMLInputElement,
    React.InputHTMLAttributes<HTMLInputElement>
  >((props, ref) => (
    <input
      {...props}
      ref={ref}
      style={{ cursor: loading ? 'not-allowed' : 'pointer' }}
    />
  ));

  useEffect(() => {
    if (!error) applySmartADR();
  }, [
    sessionStorage.getItem(SessionStorageConstants.EPIC_ACCESS_TOKEN),
    sessionStorage.getItem(SessionStorageConstants.EPIC_PATIENT_ID),
  ]);

  return (
    <>
      <div id='smart-adr'>
        {dateFilter && (
          <DaterPickerAlignment>
            <FilterText>Date Filter :</FilterText>
            <DatePicker
              disabled={loading}
              className='custom-datepicker'
              selectsRange={true}
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              onChange={(update: any) => {
                setDateRange(update);
              }}
              customInput={<CustomDatePickerInput />}
              maxDate={maxDate}
              isClearable={true}
              placeholderText=' Start date - End date'
            />

            <ApplyFilterButton
              style={{ cursor: loading ? 'not-allowed' : 'pointer' }}
              // onClick={applySmartADR}
            >
              {' '}
              Apply
            </ApplyFilterButton>
          </DaterPickerAlignment>
        )}
        {loading ? (
          <BasicSpinner label='Loading SmartADR Results...' padding='4rem 0' />
        ) : error ? (
          <TextSpan
            overwriteColor={theme.primary}
            weight='bold'
            padding='4rem 2.5rem'
          >
            {error}
          </TextSpan>
        ) : (
          patientdata[params.id].medication_with_adrs.map((medication: any) => {
            return (
              <SmartADRMedElement
                key={medication['id']}
                name={medication['name']}
                dosage={medication['dosageInstruction']}
                adrDetails={[]}
                highlight={''}
                monographReference={monographReference}
              />
            );
          })
        )}
      </div>
    </>
  );
};

export default SmartADRCard;
