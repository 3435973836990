import styled from "styled-components";

export const UserProfileContainer = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UserProfileElements = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImgCircle = styled.img`
  position: relative;
  object-fit: contain;
  overflow: hidden;
  border-radius: ${(props) => props.dimension};
  width: ${(props) => props.dimension};
  height: ${(props) => props.dimension};
  border: ${(props) => props.borderSize} solid ${(props) => props.theme.white};
  z-index: 5;
`;

export const UserProfileLine = styled.hr`
  width: 100%;
  position: absolute;
  bottom: 30px;
  z-index: 0;
`;

export const UserInfoDiv = styled.div`
  padding: 0.25rem; 0rem;
`;

export const Search = styled.input`
  margin: 0.5rem 0;
  width: 75%;
  height: 36px;
  padding: 0 1.5rem;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
`;

export const DoctorContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
`;

export const DoctorDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
`;