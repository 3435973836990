import 'react-datepicker/dist/react-datepicker.css';
import './myImageGallery.css';

import {
  CardsContainer,
  SummaryContainer,
} from '../../components/summary/summary.style';
import { Tab, TabContainer, TabContent } from '../../components/tab/tab.style';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useEffect, useState } from 'react';

import { GiMedicines } from 'react-icons/gi';
import Heading from '../../components/atoms/heading';
import HorizontalLine from '../../components/atoms/horizontal-line';
import { Image } from 'antd';
import LabsCard from '../../components/lab-cards/labs-card';
import Layout from '../../components/layout/layout';
import MedCheck from '../../components/med-check/med-check';
import MedicalProblems from '../../components/medical-problems-card/medical-problems';
import MonographCard from '../../components/monograph-demo/monograph-card';
import ReadingsCard from '../../components/atoms/readings-card/readings-card';
import SmartADRCard from '../../components/smart-adr/smart-adr-card';
import Tour from 'reactour';
import VitalsCard from '../../components/viatls-card/vitals-card';
import stepEleven from '../../assets/images/step_by_step_walkthrough_images /step_eleven.png';
import stepFifteen from '../../assets/images/step_by_step_walkthrough_images /step_fifteen.png';
import stepFour from '../../assets/images/step_by_step_walkthrough_images /step_four.png';
import stepOne from '../../assets/images/step_by_step_walkthrough_images /step_one.png';
import stepSeven from '../../assets/images/step_by_step_walkthrough_images /step_seven.png';
import stepSix from '../../assets/images/step_by_step_walkthrough_images /step_six_new.png';
import stepSixteen from '../../assets/images/step_by_step_walkthrough_images /step_sixteen.png';
import stepThirteen from '../../assets/images/step_by_step_walkthrough_images /step_thirteen.png';
import stepTwelve from '../../assets/images/step_by_step_walkthrough_images /step_twelve.png';
import stepTwenty from '../../assets/images/step_by_step_walkthrough_images /step_twenty_new.png';
import stepTwentyEight from '../../assets/images/step_by_step_walkthrough_images /step_twentyEight.png';
import stepTwentyFive from '../../assets/images/step_by_step_walkthrough_images /step_twentyFive.png';
import stepTwentyNine from '../../assets/images/step_by_step_walkthrough_images /step_twentyNine.png';
import stepTwentyOne from '../../assets/images/step_by_step_walkthrough_images /step_twentyOne.png';
import stepTwentySeven from '../../assets/images/step_by_step_walkthrough_images /step_twentySeven.png';
import stepTwentySix from '../../assets/images/step_by_step_walkthrough_images /step_TwentySix.png';
import stepTwentyTwo from '../../assets/images/step_by_step_walkthrough_images /step_twentyTwo.png';
import stepTwo from '../../assets/images/step_by_step_walkthrough_images /step_two_new.png';
import theme from '../../global/theme/theme';
import { useParams } from 'react-router-dom';

/**
 * Wraps everything into `FhirClientProvider` so that any component
 * can have access to the fhir client through the context.
 */
const HomePage = () => {
  const [activeTab, setActiveTab] = useState('Indication/Dosing');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [medCheckDateFilter, setMedCheckDateFilter] = useState(false);
  const [isTourOpen, setTourOpen] = useState<boolean>(false);
  const params: any = useParams();
  const disableBody = (target: any) => disableBodyScroll(target);
  const enableBody = (target: any) => enableBodyScroll(target);
  const [currentStep, setCurrentStep] = useState(0);
  const [alertType, setAlertType] = useState('medication');
  const [openHepaticMonographContent, setOpenHepaticMonographContent] =
    useState<boolean>(false);
  const [openHepaticTab, setHepaticTabOpen] = useState<boolean>(false);
  const [openSmartADrMonographContent, setOpenSmartADrMonographContent] =
    useState<boolean>(false);
  const [openSmartAdrTab, setSmartAdrTabOpen] = useState<boolean>(false);
  const [openDdiMonographContent, setOpenDdiMonographContent] =
    useState<boolean>(false);
  const [openDdiTab, setDdiTabOpen] = useState<boolean>(false);
  const [allowNextAndPreviousButton, setAllowNextAndPreviousButton] =
    useState<boolean>(true);
  const [isPreviewImageOpen, setIsPreviewImageOpen] = useState<boolean>(false);
  const indexOnPreviousStateChanges = [
    1, 2, 6, 7, 9, 10, 11, 15, 13, 14, 16, 17, 18, 19, 20, 22, 23, 24, 25, 26,
    27, 28, 30,
  ];
  const indexOnNextStateChanges = [
    1, 2, 5, 6, 8, 9, 10, 13, 14, 15, 16, 17, 18, 19, 20, 22, 23, 24, 25, 26,
    27, 29,
  ];
  const { id } = useParams();

  useEffect(() => {
    if (id === 'Frank_Pharma_Tour') {
      window.scroll(0, 580);

      setTimeout(() => {
        setTourOpen(true);
      }, 1000);
    }
  }, []);

  useEffect(() => {
    setActiveTab('Indication/Dosing');
    setAlertType('medication');
  }, [isTourOpen]);

  const tourSteps = [
    //1
    {
      selector: '#smart-adr-container',
      style: {
        left: isPreviewImageOpen
          ? window.innerWidth < 1500
            ? '500px'
            : '730px'
          : '',

        maxWidth: '400px',
      },
      content: () => (
        <div>
          Integrates with both Elsevier’s ClinicalPharmacology drug database and
          Epic EHR to create a medication list capable of providing
          patient-specific alerts, in real-time, while avoiding workflow
          interruptions, alert fatigue, and information overload.
          <div
            style={{
              width: '100%',
              marginTop: '15px',
            }}
          >
            <Image.PreviewGroup
              preview={{
                visible: isPreviewImageOpen,
                onVisibleChange: (value) => {
                  setIsPreviewImageOpen(value);
                },
                onChange: (current, prev) =>
                  console.log(`current index: ${current}, prev index: ${prev}`),
              }}
            >
              <Image width={'100%'} src={stepOne} />
            </Image.PreviewGroup>
          </div>
        </div>
      ),
    },
    //2
    {
      selector: '#smartPharm-tabs',
      style: {
        left: isPreviewImageOpen
          ? window.innerWidth < 1500
            ? '570px'
            : '730px'
          : '',
        maxWidth: '340px',
      },
      content: () => (
        <div>
          Passive alerts provide insight from the FDA drug monographs of every
          medication found in ClinicalPharmacology. The tabs above the
          medication list dictate the type of alerts being displayed, with each
          tab showing alerts from a specific section of the monograph.
          <div>
            <Image.PreviewGroup
              preview={{
                visible: isPreviewImageOpen,
                onVisibleChange: (value) => {
                  setIsPreviewImageOpen(value);
                },
                onChange: (current, prev) =>
                  console.log(`current index: ${current}, prev index: ${prev}`),
              }}
            >
              <Image width={'100%'} height={350} src={stepTwo} />
            </Image.PreviewGroup>
          </div>
        </div>
      ),
    },
    //3
    {
      selector: '#vitals-card',
      highlightedSelectors: ['#labs'],
      content: () => (
        <div>
          What makes SmartPharm unique is its real-time and continuous access to
          information found on the patient chart, which is used to scrape the
          entirety of Elsevier’s drug database for any clinically relevant
          monograph content related to a patient.
        </div>
      ),
    },
    //4
    {
      selector: '#Indication-Dosing',
      style: {
        left: isPreviewImageOpen
          ? window.innerWidth < 1500
            ? '530px'
            : '730px'
          : '',

        maxWidth: '400px',
      },
      content: () => (
        <div>
          The indication & dosing tab provides a clinician with alerts regarding
          inappropriate dosing of a medication.
          <div
            style={{
              width: '100%',
              marginTop: '15px',
            }}
          >
            <Image.PreviewGroup
              preview={{
                visible: isPreviewImageOpen,
                onVisibleChange: (value) => {
                  setIsPreviewImageOpen(value);
                },
                onChange: (current, prev) =>
                  console.log(`current index: ${current}, prev index: ${prev}`),
              }}
            >
              <Image width={'100%'} src={stepFour} />
            </Image.PreviewGroup>
          </div>
        </div>
      ),
    },
    //5
    {
      selector: '#Indication-dosing-by-medication',
      content: () => (
        <div>
          Alerts in this tab include inappropriate dosing based on indication,
          or inappropriate dosing based on patients renal or hepatic function.
        </div>
      ),
    },
    //6
    {
      selector: '#uncontrolled-tab-example-tab-3',
      style: {
        left: isPreviewImageOpen
          ? window.innerWidth < 1500
            ? '530px'
            : '730px'
          : '',

        maxWidth: '600px',
      },
      content: () => (
        <div>
          In this example, oxycodone is a medication that requires a dose
          adjustment in patients with poor hepatic function. Since liver enzymes
          are elevated in this patient, the hepatic dosing alert is triggered in
          real time.
          <div
            style={{
              width: '100%',
              marginTop: '15px',
            }}
          >
            <img width={'100%'} height={150} src={stepSix} alt='' />
          </div>
        </div>
      ),
    },
    //7
    {
      selector: '#Oxycodone-Indication-Dosing',
      style: {
        left: isPreviewImageOpen
          ? window.innerWidth < 1500
            ? '530px'
            : '730px'
          : '',
        maxWidth: '400px',
      },
      content: () => (
        <div>
          Clicking the hepatic tab shows the respective monograph content for
          the medication.
          <div
            style={{
              width: '100%',
              marginTop: '15px',
            }}
          >
            <Image.PreviewGroup
              preview={{
                visible: isPreviewImageOpen,
                onVisibleChange: (value) => {
                  setIsPreviewImageOpen(value);
                },
                onChange: (current, prev) =>
                  console.log(`current index: ${current}, prev index: ${prev}`),
              }}
            >
              <Image width={'100%'} src={stepSeven} />
            </Image.PreviewGroup>
          </div>
        </div>
      ),
    },
    //8
    {
      selector: '#alert-type-tabs',
      content: () => (
        <div>
          The option to view SmartPharm findings in the context of each
          medication or in the context of alert type are both available. When
          viewing by medication, clinicians can see which medications require
          attention due to a potentially inappropriate dose.
        </div>
      ),
    },
    //9
    {
      selector: '#Indication-dosing-by-type',
      content: () => (
        <div>
          When viewing by type, clinicians can choose to see only the
          medications requiring a specific type of dose correction.
        </div>
      ),
    },
    //10
    {
      selector: '#Indication-dosing-by-hepatic',
      content: () => (
        <div>
          Clicking the hepatic section will present the clinician with all
          medications requiring hepatic dose adjustments.
        </div>
      ),
    },
    //11
    {
      selector: '#aspirin-monograph-content',
      style: {
        left: isPreviewImageOpen
          ? window.innerWidth < 1500
            ? '580px'
            : '730px'
          : '',

        maxWidth: '350px',
      },
      content: () => (
        <div>
          If the clinician wants to know more information, clicking the hepatic
          tab will show the respective monograph content for the given
          medication.
          <div
            style={{
              width: '100%',
              marginTop: '15px',
            }}
          >
            <Image.PreviewGroup
              preview={{
                visible: isPreviewImageOpen,
                onVisibleChange: (value) => {
                  setIsPreviewImageOpen(value);
                },
                onChange: (current, prev) =>
                  console.log(`current index: ${current}, prev index: ${prev}`),
              }}
            >
              <Image width={'100%'} src={stepEleven} />
            </Image.PreviewGroup>
          </div>
        </div>
      ),
    },
    //12
    {
      selector: '#smart-adr',
      style: {
        left: isPreviewImageOpen
          ? window.innerWidth < 1500
            ? '550px'
            : '730px'
          : '',

        maxWidth: '350px',
      },
      content: () => (
        <div>
          The ADR tab provides a clinician with alerts regarding adverse drug
          reactions of a medication.
          <div
            style={{
              width: '100%',
              marginTop: '15px',
            }}
          >
            <Image.PreviewGroup
              preview={{
                visible: isPreviewImageOpen,
                onVisibleChange: (value) => {
                  setIsPreviewImageOpen(value);
                },
                onChange: (current, prev) =>
                  console.log(`current index: ${current}, prev index: ${prev}`),
              }}
            >
              <Image width={'100%'} src={stepTwelve} />
            </Image.PreviewGroup>
          </div>
        </div>
      ),
    },
    //13
    {
      selector: '#smart-adr-tab-ui',
      style: {
        left: isPreviewImageOpen
          ? window.innerWidth < 1500
            ? '570px'
            : '730px'
          : '',

        maxWidth: '320px',
      },
      content: () => (
        <div>
          The color of the medication represents the incidence rate of the most
          common ADR found for each medication. If no ADR is present, there is
          no color change. If the most common ADR found is considered to be rare
          (&lt;1% incidence rate), the medication will turn green. If infrequent
          (1-10%), the medication turns yellow. If common (&gt;10%), the
          medication turns red.
          <div
            style={{
              width: '100%',
              marginTop: '15px',
            }}
          >
            <Image.PreviewGroup
              preview={{
                visible: isPreviewImageOpen,
                onVisibleChange: (value) => {
                  setIsPreviewImageOpen(value);
                },
                onChange: (current, prev) =>
                  console.log(`current index: ${current}, prev index: ${prev}`),
              }}
            >
              <Image width={'100%'} src={stepThirteen} />
            </Image.PreviewGroup>
          </div>
        </div>
      ),
    },
    //14
    {
      selector: '#smart-adr-by-medication',
      content: () => (
        <div>
          A summary is provided below the names of each medication to help
          provide a breakdown of how many ADRs of each incidence rate was found
          for each medication.
        </div>
      ),
    },
    //15
    {
      selector: '#smart-adr-by-medication',
      style: {
        left: isPreviewImageOpen
          ? window.innerWidth < 1500
            ? '560px'
            : '730px'
          : '',
        maxWidth: '320px',
      },
      content: () => (
        <div>
          By clicking the common ADRs tab, a list of ADRs known to be caused by
          the given medication at a rate &gt;10% (as well as severity of each)
          will be provided to the clinician based on clinical findings of their
          patient.
          <div
            style={{
              width: '100%',
              marginTop: '15px',
            }}
          >
            <Image.PreviewGroup
              preview={{
                visible: isPreviewImageOpen,
                onVisibleChange: (value) => {
                  setIsPreviewImageOpen(value);
                },
                onChange: (current, prev) =>
                  console.log(`current index: ${current}`),
              }}
            >
              <Image width={'100%'} src={stepFifteen} />
            </Image.PreviewGroup>
          </div>
        </div>
      ),
    },
    //16
    {
      selector: '#uncontrolled-tab-example-tabpane-3 > div:nth-child(1)',
      style: {
        left: isPreviewImageOpen
          ? window.innerWidth < 1500
            ? '560px'
            : '730px'
          : '',
        maxWidth: '320px',
      },
      content: () => (
        <div>
          Clicking an ADR will provide a summary of clinical evidence regarding
          the specific ADR, which is typically found in the ClinicalPharmacology
          monograph.
          <div
            style={{
              width: '100%',
              marginTop: '15px',
            }}
          >
            <Image.PreviewGroup
              preview={{
                visible: isPreviewImageOpen,
                onVisibleChange: (value) => {
                  setIsPreviewImageOpen(value);
                },
                onChange: (current, prev) =>
                  console.log(`current index: ${current}, prev index: ${prev}`),
              }}
            >
              <Image width={'100%'} src={stepSixteen} />
            </Image.PreviewGroup>
          </div>
        </div>
      ),
    },
    //17
    {
      selector: '#smart-adr-tab-ui',
      content: () => (
        <div>
          Alerts can also be structured by incidence rate, allowing clinicians
          to view ADRs based on frequency rather than medications.
        </div>
      ),
    },
    //18
    {
      selector: '#smart-adr-by-infrequent-frequency',
      content: () => (
        <div>
          In this scenario, the clinician clicks on the infrequent section to
          see the 8 infrequent ADRs and the medications causing each one.
          Clicking a specific ADR would once again provide a clinical summary
          for the specific ADR as well.
        </div>
      ),
    },
    //19
    {
      selector: '#smart-adr-tab-ui',
      content: () => (
        <div>
          Alerts can also be structured based on the ADR condition, where the
          same color-coded system highlights the highest incidence rate found.
        </div>
      ),
    },
    //20
    {
      selector: '#Bradycardia-by-condition',
      style: {
        left: isPreviewImageOpen
          ? window.innerWidth < 1500
            ? '560px'
            : '730px'
          : '',
        maxWidth: '320px',
      },
      content: () => (
        <div>
          In this example, the clinician sees there are 3 medications
          potentially causing bradycardia in their patient. Amiodarone and
          carvedilol are known to cause bradycardia in &gt;10% of patients while
          aripiprazole causes bradycardia in &lt;1% of patients. Similarly,
          clicking a specific ADR will display its respective clinical summary
          of evidence.
          <div
            style={{
              width: '100%',
              marginTop: '15px',
            }}
          >
            <img width={'80%'} height={150} src={stepTwenty} alt='' />
          </div>
        </div>
      ),
    },
    //21
    {
      selector: '#DDI',
      style: {
        left: isPreviewImageOpen
          ? window.innerWidth < 1500
            ? '560px'
            : '730px'
          : '',
        maxWidth: '320px',
      },
      content: () => (
        <div>
          The DDI tab provides a clinician with alerts regarding drug-drug
          interactions.
          <div
            style={{
              width: '100%',
              marginTop: '15px',
            }}
          >
            <Image.PreviewGroup
              preview={{
                visible: isPreviewImageOpen,
                onVisibleChange: (value) => {
                  setIsPreviewImageOpen(value);
                },
                onChange: (current, prev) =>
                  console.log(`current index: ${current}, prev index: ${prev}`),
              }}
            >
              <Image width={'100%'} height={200} src={stepTwentyOne} />
            </Image.PreviewGroup>
          </div>
        </div>
      ),
    },
    //22
    {
      selector: '#smart-adr-tab-ui',
      style: {
        left: isPreviewImageOpen
          ? window.innerWidth < 1500
            ? '560px'
            : '730px'
          : '',
        maxWidth: '320px',
      },
      content: () => (
        <div>
          The color of the medication represents the severity of the most severe
          drug-drug interaction found for each medication. If no DDI is present,
          there is no color change. If the most severe DDI found is considered
          to be a minor interaction, the medication will turn green. If
          moderate, the medication turns yellow. If it’s a major interaction,
          the medication turns orange and since contraindicated drug
          interactions are most severe, it triggers a color change to red.
          <div
            style={{
              width: '100%',
              marginTop: '15px',
            }}
          >
            <Image.PreviewGroup
              preview={{
                visible: isPreviewImageOpen,
                onVisibleChange: (value) => {
                  setIsPreviewImageOpen(value);
                },
                onChange: (current, prev) =>
                  console.log(`current index: ${current}, prev index: ${prev}`),
              }}
            >
              <Image width={'100%'} height={200} src={stepTwentyTwo} />
            </Image.PreviewGroup>
          </div>
        </div>
      ),
    },
    //23
    {
      selector: '#DDI-by-medication',
      content: () => (
        <div>
          A breakdown of DDI findings is provided below the names of each
          medication.
        </div>
      ),
    },
    //24
    {
      selector: '#DDI-by-medication',
      content: () => (
        <div>
          Clicking the major DDI tab provides a list of major drug-drug
          interactions based on the drugs found in a patient’s medication list.
        </div>
      ),
    },
    //25
    {
      selector: ' #uncontrolled-tab-example-tabpane-3 > div:nth-child(1)',
      style: {
        left: isPreviewImageOpen
          ? window.innerWidth < 1500
            ? '560px'
            : '730px'
          : '',
        maxWidth: '320px',
      },
      content: () => (
        <div>
          Clicking a drug provides a summary of clinical evidence regarding the
          drug interaction, as found in the ClinicalPharmacology drug database.
          <div
            style={{
              width: '100%',
              marginTop: '15px',
            }}
          >
            <Image.PreviewGroup
              preview={{
                visible: isPreviewImageOpen,
                onVisibleChange: (value) => {
                  setIsPreviewImageOpen(value);
                },
                onChange: (current, prev) =>
                  console.log(`current index: ${current}, prev index: ${prev}`),
              }}
            >
              <Image width={'100%'} height={200} src={stepTwentyFive} />
            </Image.PreviewGroup>
          </div>
        </div>
      ),
    },
    //26
    {
      selector: '#smart-adr-tab-ui',
      style: {
        left: isPreviewImageOpen
          ? window.innerWidth < 1500
            ? '560px'
            : '730px'
          : '',
        maxWidth: '320px',
      },
      content: () => (
        <div>
          Alerts can also be structured by severity, allowing for a clinician to
          quickly view all interactions based on severity, similar to
          ClinicalPharmacology’s drug interaction tool.
          <div
            style={{
              width: '100%',
              marginTop: '15px',
            }}
          >
            <Image.PreviewGroup
              preview={{
                visible: isPreviewImageOpen,
                onVisibleChange: (value) => {
                  setIsPreviewImageOpen(value);
                },
                onChange: (current, prev) =>
                  console.log(`current index: ${current}, prev index: ${prev}`),
              }}
            >
              <Image width={'100%'} height={200} src={stepTwentySix} />
            </Image.PreviewGroup>
          </div>
        </div>
      ),
    },
    //27
    {
      selector: '#ddi-by-severity-major',
      style: {
        left: isPreviewImageOpen
          ? window.innerWidth < 1500
            ? '560px'
            : '730px'
          : '',
        maxWidth: '320px',
      },
      content: () => (
        <div>
          Similar to ClinicalPharmacology’s drug interaction tool, clicking into
          each section provides a list of drug-drug interactions of the given
          severity. In this case, all 3 major drug-drug interactions are
          presented.
          <div
            style={{
              width: '100%',
              marginTop: '15px',
            }}
          >
            <Image.PreviewGroup
              preview={{
                visible: isPreviewImageOpen,
                onVisibleChange: (value) => {
                  setIsPreviewImageOpen(value);
                },
                onChange: (current, prev) =>
                  console.log(`current index: ${current}, prev index: ${prev}`),
              }}
            >
              <Image width={'100%'} height={200} src={stepTwentySeven} />
            </Image.PreviewGroup>
          </div>
        </div>
      ),
    },
    //28
    {
      selector: ' #ddi-by-severity-major > div:nth-child(3)',
      style: {
        left: isPreviewImageOpen
          ? window.innerWidth < 1500
            ? '560px'
            : '730px'
          : '',
        maxWidth: '320px',
      },
      content: () => (
        <div>
          Clicking on a drug-drug interaction will present the clinician with a
          summary of evidence regarding the specific interaction, exactly as
          found in ClinicalPharmacology.
          <div
            style={{
              width: '100%',
              marginTop: '15px',
            }}
          >
            <Image.PreviewGroup
              preview={{
                visible: isPreviewImageOpen,
                onVisibleChange: (value) => {
                  setIsPreviewImageOpen(value);
                },
                onChange: (current, prev) =>
                  console.log(`current index: ${current}, prev index: ${prev}`),
              }}
            >
              <Image width={'100%'} height={200} src={stepTwentyEight} />
            </Image.PreviewGroup>
          </div>
        </div>
      ),
    },
    //29
    {
      selector: '#Contraindication',
      style: {
        left: isPreviewImageOpen
          ? window.innerWidth < 1500
            ? '560px'
            : '730px'
          : '',
        maxWidth: '320px',
      },
      content: () => (
        <div>
          The contraindication tab provides a clinician with alerts regarding
          the presence of contraindications for any given medication as well as
          notifications for black box warnings, pregnancy/lactation precautions,
          Beer’s Criteria, and duplicate therapies.
          <div
            style={{
              width: '100%',
              marginTop: '15px',
            }}
          >
            <Image.PreviewGroup
              preview={{
                visible: isPreviewImageOpen,
                onVisibleChange: (value) => {
                  setIsPreviewImageOpen(value);
                },
                onChange: (current, prev) =>
                  console.log(`current index: ${current}, prev index: ${prev}`),
              }}
            >
              <Image width={'100%'} height={200} src={stepTwentyNine} />
            </Image.PreviewGroup>
          </div>
        </div>
      ),
    },
    {
      selector: '#contraindication-by-medication',
      content: () => (
        <div>
          A breakdown of findings is provided below the name of each medication,
          and similar to the Indication/Dosing tab, clinical summaries for each
          alert are provided upon further investigation.
        </div>
      ),
    },
    {
      selector: '#smart-adr-tab-ui',
      content: () => (
        <div>
          Alerts can also be organized in accordance with the type of alert,
          allowing clinicians to quickly locate problematic medications based on
          certain criteria they’re looking for.
        </div>
      ),
    },
    {
      selector: '#smartPharm-tabs',
      style: { right: '900px' },
      content: () => (
        <div>
          In summary, SmartPharm integrates with Epic EHR and Elsevier’s
          ClinicalPharmacology drug database, allowing it to use a patient’s
          presentation to quickly locate and retrieve clinically relevant
          findings for a clinician, and making it readily available to them from
          inside the patient chart. As a result, a clinician can view a
          patient’s clinical presentation in the context of an entire drug
          database in just a few seconds, helping them to optimize medication
          safety and efficacy in patients without any worries of information
          overload, workflow interruptions, or alert fatigue.
        </div>
      ),
    },
  ];

  const handlePrevStep = () => {
    console.log(currentStep);
    if (isPreviewImageOpen) {
      setIsPreviewImageOpen(!isPreviewImageOpen);
    }
    if (indexOnPreviousStateChanges.includes(currentStep)) {
      if (currentStep === 1) window.scroll(0, 600);
      if (currentStep === 2) window.scroll(0, 580);

      if (currentStep === 6 || currentStep === 10)
        setOpenHepaticMonographContent(false);

      if (currentStep === 7) {
        setOpenHepaticMonographContent(true);
        setAlertType('medication');
      }

      if (currentStep === 9) setHepaticTabOpen(false);

      if (currentStep === 11) {
        setActiveTab('Indication/Dosing');
        setAlertType('type');
      }
      if (currentStep === 20) {
        setActiveTab('ADR');
        setAlertType('condition');
        setSmartAdrTabOpen(true);
      }

      if (currentStep === 15) {
        setOpenSmartADrMonographContent(false);
      }
      if (currentStep === 13 || currentStep === 16 || currentStep === 18) {
        setSmartAdrTabOpen(true);
      }
      if (currentStep === 14 || currentStep === 17 || currentStep === 19) {
        setSmartAdrTabOpen(false);
      }
      if (currentStep === 16 || currentStep === 30) {
        setAlertType('medication');
      }
      if (currentStep === 18) {
        setAlertType('type');
      }

      if (currentStep === 23 || currentStep === 26) {
        setDdiTabOpen(false);
      }
      if (currentStep === 24 || currentStep === 27) {
        setOpenDdiMonographContent(false);
      }

      if (currentStep === 25) {
        setAlertType('medication');
        if (openDdiTab) {
          setDdiTabOpen(false);
        }
        setOpenDdiMonographContent(true);
      }

      if (currentStep === 28) {
        setActiveTab('DDI');
        setAlertType('type');
      }

      setTimeout(
        () => {
          setCurrentStep((prev) => {
            let prevStep = prev > 0 ? prev - 1 : prev;
            return prevStep;
          });
        },
        currentStep === 2 || currentStep === 1 ? 1000 : 100
      );
    } else
      setCurrentStep((prev) => {
        let prevStep = prev > 0 ? prev - 1 : prev;
        return prevStep;
      });
    // Adjust the delay as needed
  };

  const handleNextStep = () => {
    if (isPreviewImageOpen) {
      setIsPreviewImageOpen(!isPreviewImageOpen);
    }
    setAllowNextAndPreviousButton(false);
    console.log(currentStep);
    if (indexOnNextStateChanges.includes(currentStep)) {
      if (currentStep === 1) window.scroll(0, 100);
      if (currentStep === 2) window.scroll(0, 580);
      if (currentStep === 5 || currentStep === 9)
        setOpenHepaticMonographContent(true);
      if (currentStep === 6 || currentStep === 29) {
        setOpenHepaticMonographContent(false);
        setAlertType('type');
      }
      if (currentStep === 8) setHepaticTabOpen(true);
      if (currentStep === 10) {
        setActiveTab('ADR');
        setAlertType('medication');
      }
      if (currentStep === 13 || currentStep === 16 || currentStep === 18) {
        setSmartAdrTabOpen(true);
      }
      if (currentStep === 14 || currentStep === 17 || currentStep === 19) {
        setSmartAdrTabOpen(false);
      }
      if (currentStep === 14) {
        setOpenSmartADrMonographContent(true);
      }
      if (currentStep === 15) {
        setAlertType('type');
      }

      if (currentStep === 17) {
        setAlertType('condition');
      }
      if (currentStep === 19) {
        setActiveTab('DDI');
        setAlertType('medication');
      }
      if (currentStep === 22 || currentStep === 25) {
        setDdiTabOpen(true);
      }
      if (currentStep === 23 || currentStep === 26) {
        setOpenDdiMonographContent(true);
      }

      if (currentStep === 24) {
        setAlertType('type');
        setDdiTabOpen(false);
        setOpenDdiMonographContent(false);
      }
      if (currentStep === 27) {
        setActiveTab('Contraindication');
        setAlertType('medication');
      }

      setTimeout(
        () => {
          setCurrentStep((prev) => {
            let nextStep = prev < tourSteps.length - 1 ? prev + 1 : prev;
            return nextStep;
          });
          setAllowNextAndPreviousButton(true);
        },
        currentStep === 2 ? 500 : 100
      ); // Adjust the delay as needed
    } else {
      setCurrentStep((prev) => {
        let nextStep = prev < tourSteps.length - 1 ? prev + 1 : prev;
        return nextStep;
      });
      setAllowNextAndPreviousButton(true);
    }
  };

  return (
    <div>
      <Layout setTour={setTourOpen} setTourIndex={setCurrentStep}>
        <SummaryContainer>
          <Heading level={1} color={theme.text.blue} margin='1rem 0 0.5rem 0'>
            Summary
          </Heading>
          <div style={{ display: 'flex', gap: '20px' }}>
            <CardsContainer>
              <div>
                <div style={{ display: 'flex', gap: '20px' }}>
                  <VitalsCard></VitalsCard>
                  <MedicalProblems></MedicalProblems>
                </div>
                {currentStep !== 2 && (
                  <div id='smart-adr-container'>
                    <ReadingsCard
                      color={theme.primary}
                      title={
                        id?.includes('Standard') ? 'Medications' : 'SmartPharm'
                      }
                      icon={<GiMedicines />}
                      id={'smart-Card'}
                    >
                      {id !== 'Frank_Pharma_Standard' &&
                        id !== 'Wendy_Williams_Standard' && (
                          <TabContainer id='smartPharm-tabs'>
                            <Tab
                              id='Indication-Dosing'
                              color={theme.primary}
                              className={
                                activeTab === 'Indication/Dosing'
                                  ? 'active'
                                  : ''
                              }
                              onClick={() => setActiveTab('Indication/Dosing')}
                            >
                              Indication/Dosing&nbsp;
                              {params.id === 'Frank_Pharma'
                                ? '(4)'
                                : params.id === 'Wendy_Williams'
                                ? '(6)'
                                : ''}
                            </Tab>
                            <Tab
                              id='smart-adr'
                              backgroundColor={
                                params.id === 'Frank_Pharma'
                                  ? '#FFEDED'
                                  : params.id === 'John_Doe'
                                  ? '#FFEDED'
                                  : '#FFEDED'
                              }
                              color={theme.primary}
                              className={activeTab === 'ADR' ? 'active' : ''}
                              onClick={() => setActiveTab('ADR')}
                            >
                              ADR
                            </Tab>
                            <Tab
                              id={'DDI'}
                              backgroundColor={
                                params.id === 'Frank_Pharma'
                                  ? '#FAD5A5'
                                  : params.id === 'John_Doe'
                                  ? '#FAD5A5'
                                  : '#FDFFD9'
                              }
                              color={theme.primary}
                              className={activeTab === 'DDI' ? 'active' : ''}
                              onClick={() => setActiveTab('DDI')}
                            >
                              DDI
                            </Tab>
                            <Tab
                              id='Contraindication'
                              color={theme.primary}
                              className={
                                activeTab === 'Contraindication' ? 'active' : ''
                              }
                              onClick={() => setActiveTab('Contraindication')}
                            >
                              Contraindication&nbsp;
                              {params.id === 'Frank_Pharma'
                                ? '(5)'
                                : params.id === 'Wendy_Williams'
                                ? '(5)'
                                : ''}
                            </Tab>
                          </TabContainer>
                        )}
                      <HorizontalLine
                        width='100%'
                        opacity='0.35'
                        margin='0.5rem 0rem 0rem 0rem'
                      />
                      {id !== 'Frank_Pharma_Standard' &&
                      id !== 'Wendy_Williams_Standard' ? (
                        <TabContent>
                          {(activeTab === 'Indication/Dosing' ||
                            activeTab === 'ADR' ||
                            activeTab === 'DDI' ||
                            activeTab === 'Contraindication') && (
                            <div id='smart-adr-tab-ui'>
                              <MonographCard
                                MonographType={activeTab}
                                OpenHepaticMonographContent={
                                  openHepaticMonographContent
                                }
                                alertType={alertType}
                                setAlertType={setAlertType}
                                OpenHepaticTab={openHepaticTab}
                                openSmartADrMonographContent={
                                  openSmartADrMonographContent
                                }
                                openSmartAdrTab={openSmartAdrTab}
                                openDdiMonographContent={
                                  openDdiMonographContent
                                }
                                openDdiTab={openDdiTab}
                              />
                            </div>
                          )}
                          {activeTab === 'MedCheck' && (
                            <MedCheck dateFilter={medCheckDateFilter} />
                          )}
                        </TabContent>
                      ) : (
                        <TabContent>
                          <SmartADRCard />
                        </TabContent>
                      )}
                    </ReadingsCard>
                  </div>
                )}

                {/* <LabsCard></LabsCard> */}
              </div>
            </CardsContainer>
            <LabsCard></LabsCard>
          </div>
        </SummaryContainer>
      </Layout>
      <Tour
        steps={tourSteps}
        isOpen={isTourOpen}
        onRequestClose={() => setTourOpen(false)}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        prevStep={handlePrevStep}
        nextStep={handleNextStep}
        goToStep={currentStep}
        disableDotsNavigation={true}
        closeWithMask={false}
        disableInteraction={false}
        showButtons={allowNextAndPreviousButton}
        disableKeyboardNavigation={allowNextAndPreviousButton ? false : true}
        maskSpace={
          isPreviewImageOpen ? (window.innerWidth < 1500 ? 1000 : 1500) : 0
        }
      />
    </div>
  );
};

export default HomePage;
