import styled from 'styled-components';

export const LabReadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 160px;
  // height: 80px;
  margin: 1rem 1rem;
`;

export const ReadingValueContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const RangeContainer = styled.div`
  display: flex;
  width: 10px;
  height: 28px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.lightGrey};
`;

export const RangeBubble = styled.div`
  display: flex;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${(props) => props.color};
  align-self: ${(props) => props.alignment};
`;
