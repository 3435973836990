import '../tab/tab.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  AdrDetails,
  AdrNameAndSeverity,
  CheckBox,
  TabContainer,
  TabHeading,
} from './by-type.style';
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md';
import {
  ModalAdrInfo,
  ModalCard,
  ModalCardMain,
} from '../modal/monograph-modal.style';
import React, { useEffect, useState } from 'react';

import { AdrData } from '../../../types/smart-adr.types';
import checkBox from '../constants/svg/checkBox.svg';
import greenCheckBox from '../constants/svg/greenCheckBox.svg';
import orangeCheckBox from '../constants/svg/orangeSvg.svg';
import redCheckBox from '../constants/svg/redCheckBox.svg';
import yellowCheckBox from '../constants/svg//yellowCheckBox.svg';

const DrugDrugInteractionByType: React.FC<any> = ({
  DrugDrugInteractionData,
  openDdiMonographContent,
  openDdiTab,
}) => {
  const [selectedAdr, setSelectedAdr] = useState<AdrData>({
    id: '',
    adrName: '',
  });
  const [activeTab, setActiveTab] = useState<string>('');

  useEffect(() => {
    if (openDdiTab) {
      setActiveTab('Major');
    } else {
      setActiveTab('');
    }
  }, [openDdiTab]);

  useEffect(() => {
    if (openDdiMonographContent) {
      // setActiveTab('Major');
      if (DrugDrugInteractionData.major) {
        setSelectedAdr({
          id: '1',
          adrName: Object.keys(DrugDrugInteractionData.major[0])[0],
        });
      }
    } else {
      setSelectedAdr({
        id: '',
        adrName: '',
      });
    }
  }, [openDdiMonographContent]);

  return (
    <>
      <TabContainer
        style={{
          pointerEvents:
            DrugDrugInteractionData.severe.length === 0 ? 'none' : 'auto',
        }}
        background='#FFEDED'
        border='#EDBBBB'
        onClick={() => {
          if (activeTab === 'Severe') {
            setActiveTab('');
          } else {
            setActiveTab('Severe');
          }
          setSelectedAdr({
            id: '',
            adrName: '',
          });
        }}
      >
        <TabHeading>
          {' '}
          Level 1 - Contraindicated (
          <span
            style={{
              color: DrugDrugInteractionData.severe.length > 0 ? 'red' : '',
            }}
          >
            {DrugDrugInteractionData.severe.length}
          </span>
          )
        </TabHeading>
        {activeTab === 'Severe' ? (
          <MdOutlineArrowDropUp color={'black'} size={20} />
        ) : (
          <MdOutlineArrowDropDown color={'black'} size={20} />
        )}
      </TabContainer>

      {activeTab === 'Severe' &&
        DrugDrugInteractionData.severe.map((adrDetail: any, index: any) => (
          <div>
            <div
              onClick={() => {
                if (selectedAdr.id === index) {
                  setSelectedAdr({
                    id: '',
                    adrName: '',
                  });
                } else {
                  setSelectedAdr({
                    id: index,
                    adrName: Object.keys(adrDetail)[0],
                  });
                }
              }}
            >
              <AdrDetails
                border={'1px solid #FFEDED'}
                hoverBackground={
                  selectedAdr.id === index ? '#FFC7C7' : '#ffeded'
                }
                background={selectedAdr.id === index ? '#FFC7C7' : ' #ffffff'}
              >
                <CheckBox>
                  {selectedAdr.id === index ? (
                    <img
                      style={{ marginBottom: '6px' }}
                      src={redCheckBox}
                      alt=''
                    />
                  ) : (
                    <img
                      style={{ marginBottom: '6px' }}
                      src={checkBox}
                      alt=''
                    />
                  )}
                </CheckBox>
                <AdrNameAndSeverity>
                  {adrDetail.medicationName}
                </AdrNameAndSeverity>
              </AdrDetails>
            </div>
            {activeTab === 'Severe' &&
              selectedAdr.id !== '' &&
              selectedAdr.id === index && (
                <ModalCard highlight={'#ffeded'} style={{ width: '100%' }}>
                  <ModalCardMain style={{ width: '100%' }}>
                    <ModalAdrInfo style={{ padding: '1rem 0rem' }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            DrugDrugInteractionData['severe'][selectedAdr.id]
                              .monographContent,
                        }}
                      />
                    </ModalAdrInfo>
                  </ModalCardMain>
                </ModalCard>
              )}
          </div>
        ))}

      {/* {activeTab === 'Severe' && selectedAdr.id !== '' && (
        <ModalCard highlight={'#ffeded'} style={{ width: '100%' }}>
          <ModalCardMain style={{ width: '100%' }}>
            <ModalAdrInfo style={{ padding: '1rem 0rem' }}>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    DrugDrugInteractionData['severe'][selectedAdr.id]
                      .monographContent,
                }}
              />
            </ModalAdrInfo>
          </ModalCardMain>
        </ModalCard>
      )} */}
      <div id='ddi-by-severity-major'>
        <TabContainer
          style={{
            pointerEvents:
              DrugDrugInteractionData.major.length === 0 ? 'none' : 'auto',
          }}
          background='#FAD5A5'
          border='#EDBBBB'
          onClick={() => {
            if (activeTab === 'Major') {
              setActiveTab('');
            } else {
              setActiveTab('Major');
            }
            setSelectedAdr({
              id: '',
              adrName: '',
            });
          }}
        >
          <TabHeading>
            {' '}
            Level 2 - Major (
            <span
              style={{
                color: DrugDrugInteractionData.major.length > 0 ? 'red' : '',
              }}
            >
              {DrugDrugInteractionData.major.length}
            </span>
            )
          </TabHeading>
          {activeTab === 'Major' ? (
            <MdOutlineArrowDropUp color={'black'} size={20} />
          ) : (
            <MdOutlineArrowDropDown color={'black'} size={20} />
          )}
        </TabContainer>

        {activeTab === 'Major' &&
          DrugDrugInteractionData.major.map((adrDetail: any, index: any) => (
            <div>
              <div
                onClick={() => {
                  if (selectedAdr.id === index) {
                    setSelectedAdr({
                      id: '',
                      adrName: '',
                    });
                  } else {
                    setSelectedAdr({
                      id: index,
                      adrName: Object.keys(adrDetail)[0],
                    });
                  }
                }}
              >
                <AdrDetails
                  border={'1px solid #FFEDED'}
                  hoverBackground={
                    selectedAdr.id === index
                      ? 'rgba(255, 233, 191, 0.4)'
                      : '#FFF2D9'
                  }
                  background={
                    selectedAdr.id === index
                      ? 'rgba(255, 233, 191, 0.4)'
                      : ' #ffffff'
                  }
                >
                  <CheckBox>
                    {parseInt(selectedAdr.id) === index ? (
                      <img
                        style={{ marginBottom: '6px' }}
                        src={orangeCheckBox}
                        alt=''
                      />
                    ) : (
                      <img
                        style={{ marginBottom: '6px' }}
                        src={checkBox}
                        alt=''
                      />
                    )}
                  </CheckBox>
                  <AdrNameAndSeverity>
                    {adrDetail.medicationName}
                  </AdrNameAndSeverity>
                </AdrDetails>
              </div>
              {activeTab === 'Major' &&
                selectedAdr.id !== '' &&
                parseInt(selectedAdr.id) === index && (
                  <ModalCard
                    highlight={'rgba(255, 233, 191, 0.4)'}
                    style={{ width: '100%' }}
                  >
                    <ModalCardMain style={{ width: '100%' }}>
                      <ModalAdrInfo style={{ padding: '1rem 0rem' }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              DrugDrugInteractionData['major'][
                                parseInt(selectedAdr.id)
                              ].monographContent,
                          }}
                        />
                      </ModalAdrInfo>
                    </ModalCardMain>
                  </ModalCard>
                )}
            </div>
          ))}
      </div>
      {/* {activeTab === 'Major' && selectedAdr.id !== '' && (
        <ModalCard
          highlight={'rgba(255, 233, 191, 0.4)'}
          style={{ width: '100%' }}
        >
          <ModalCardMain style={{ width: '100%' }}>
            <ModalAdrInfo style={{ padding: '1rem 0rem' }}>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    DrugDrugInteractionData['major'][selectedAdr.id]
                      .monographContent,
                }}
              />
            </ModalAdrInfo>
          </ModalCardMain>
        </ModalCard>
      )} */}
      <TabContainer
        style={{
          pointerEvents:
            DrugDrugInteractionData.moderate.length === 0 ? 'none' : 'auto',
        }}
        background='#FDFFD9'
        border='#EAEDBB'
        onClick={() => {
          if (activeTab === 'Moderate') {
            setActiveTab('');
          } else {
            setActiveTab('Moderate');
          }
          setSelectedAdr({
            id: '',
            adrName: '',
          });
        }}
      >
        <TabHeading>
          {' '}
          Level 3 - Moderate (
          <span
            style={{
              color: DrugDrugInteractionData.moderate.length > 0 ? 'red' : '',
            }}
          >
            {DrugDrugInteractionData.moderate.length}
          </span>
          )
        </TabHeading>
        {activeTab === 'Moderate' ? (
          <MdOutlineArrowDropUp color={'black'} size={20} />
        ) : (
          <MdOutlineArrowDropDown color={'black'} size={20} />
        )}
      </TabContainer>
      {activeTab === 'Moderate' &&
        DrugDrugInteractionData.moderate.map((adrDetail: any, index: any) => (
          <div>
            <div
              onClick={() => {
                if (selectedAdr.id === index) {
                  setSelectedAdr({
                    id: '',
                    adrName: '',
                  });
                } else {
                  setSelectedAdr({
                    id: index,
                    adrName: Object.keys(adrDetail)[0],
                  });
                }
              }}
            >
              <AdrDetails
                border={'1px solid #FFEDED'}
                hoverBackground={
                  selectedAdr.id === index ? '#FCFFC7' : '#feffed'
                }
                background={selectedAdr.id === index ? '#FCFFC7' : ' #ffffff'}
              >
                <CheckBox>
                  {parseInt(selectedAdr.id) === index ? (
                    <img
                      style={{ marginBottom: '6px' }}
                      src={yellowCheckBox}
                      alt=''
                    />
                  ) : (
                    <img
                      style={{ marginBottom: '6px' }}
                      src={checkBox}
                      alt=''
                    />
                  )}
                </CheckBox>
                <AdrNameAndSeverity>
                  {adrDetail.medicationName}
                </AdrNameAndSeverity>
              </AdrDetails>
            </div>
            {activeTab === 'Moderate' &&
              selectedAdr.id !== '' &&
              selectedAdr.id === index && (
                <ModalCard highlight={'#feffed'} style={{ width: '100%' }}>
                  <ModalCardMain style={{ width: '100%' }}>
                    <ModalAdrInfo style={{ padding: '1rem 0rem' }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            DrugDrugInteractionData['moderate'][selectedAdr.id]
                              .monographContent,
                        }}
                      />
                    </ModalAdrInfo>
                  </ModalCardMain>
                </ModalCard>
              )}
          </div>
        ))}
      {/* {activeTab === 'Moderate' && selectedAdr.id !== '' && (
        <ModalCard highlight={'#feffed'} style={{ width: '100%' }}>
          <ModalCardMain style={{ width: '100%' }}>
            <ModalAdrInfo style={{ padding: '1rem 0rem' }}>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    DrugDrugInteractionData['moderate'][selectedAdr.id]
                      .monographContent,
                }}
              />
            </ModalAdrInfo>
          </ModalCardMain>
        </ModalCard>
      )} */}
      <TabContainer
        style={{
          pointerEvents:
            DrugDrugInteractionData.minor.length === 0 ? 'none' : 'auto',
        }}
        background='#D9FFDF'
        border='#BBEDC3'
        onClick={() => {
          if (activeTab === 'Minor') {
            setActiveTab('');
          } else {
            setActiveTab('Minor');
          }
          setSelectedAdr({
            id: '',
            adrName: '',
          });
        }}
      >
        <TabHeading>
          {' '}
          Level 4 - Minor (
          <span
            style={{
              color: DrugDrugInteractionData.minor.length > 0 ? 'red' : '',
            }}
          >
            {DrugDrugInteractionData.minor.length}
          </span>
          )
        </TabHeading>
        {activeTab === 'Minor' ? (
          <MdOutlineArrowDropUp color={'black'} size={20} />
        ) : (
          <MdOutlineArrowDropDown color={'black'} size={20} />
        )}
      </TabContainer>
      {activeTab === 'Minor' &&
        DrugDrugInteractionData.minor.map((adrDetail: any, index: any) => (
          <div>
            <div
              onClick={() => {
                if (selectedAdr.id === index) {
                  setSelectedAdr({
                    id: '',
                    adrName: '',
                  });
                } else {
                  setSelectedAdr({
                    id: index,
                    adrName: Object.keys(adrDetail)[0],
                  });
                }
              }}
            >
              <AdrDetails
                border={'1px solid #FFEDED'}
                hoverBackground={
                  selectedAdr.id === index ? '#C7FFD0' : '#edfff0'
                }
                background={selectedAdr.id === index ? '#C7FFD0' : ' #ffffff'}
              >
                <CheckBox>
                  {selectedAdr.id === index ? (
                    <img
                      style={{ marginBottom: '6px' }}
                      src={greenCheckBox}
                      alt=''
                    />
                  ) : (
                    <img
                      style={{ marginBottom: '6px' }}
                      src={checkBox}
                      alt=''
                    />
                  )}
                </CheckBox>
                <AdrNameAndSeverity>
                  {adrDetail.medicationName}
                </AdrNameAndSeverity>
              </AdrDetails>
            </div>
            {activeTab === 'Minor' &&
              selectedAdr.id !== '' &&
              selectedAdr.id === index && (
                <ModalCard highlight={'#edfff0'} style={{ width: '100%' }}>
                  <ModalCardMain style={{ width: '100%' }}>
                    <ModalAdrInfo style={{ padding: '1rem 0rem' }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            DrugDrugInteractionData['minor'][selectedAdr.id]
                              .monographContent,
                        }}
                      />
                    </ModalAdrInfo>
                  </ModalCardMain>
                </ModalCard>
              )}
          </div>
        ))}
    </>
  );
};

export default DrugDrugInteractionByType;
