export const IndicationsTabs = [
    {
      Title: 'Indication',
      Border: '1px solid #bbedc3',
      Background: '#C7FFD0',
      HoverBackground: '#edfff0',
    },
    {
      Title: 'Renal',
      Border: '1px solid #eaedbb',
      Background: '#FCFFC7',
      HoverBackground: '#feffed',
    },
    {
      Title: 'Hepatic',
      Border: '1px solid #edbbbb',
      Background: '#FFC7C7',
      HoverBackground: '#ffeded',
    }
  ];
  