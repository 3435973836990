const UrlConstants = {
  GET_ADR: {
    USECASE: 'GET_ADR',
    URL: '/smart-adr',
    SERVER: 'SMART_ADR_BACKEND',
  },
  GET_MONOGRAPH_ADR: {
    USECASE: 'GET_MONOGRAPH_ADR',
    URL: '/gsdd/monograph',
    SERVER: 'SMART_ADR_BACKEND',
  },
  GET_PATIENT_LAB: {
    USECASE: 'GET_PATIENT_LAB',
    URL: '/epic/get-patient-lab',
    SERVER: 'SMART_ADR_BACKEND',
  },
  GET_LAB_GSTERMS: {
    USECASE: 'GET_LAB_GSTERMS',
    URL: '/loinc-adr-mapping/get-lab-gsterms',
    SERVER: 'SMART_ADR_BACKEND',
  },
  GET_MED_CHECK_ADR_DETAILS: {
    USECASE: 'GET_MED_CHECK_ADR_DETAILS',
    URL: '/smart-cpoe',
    SERVER: 'SMART_ADR_BACKEND',
  },
  GET_LAB_OBSERVATIONS: {
    USECASE: 'GET_LAB_OBSERVATIONS',
    URL: '/loinc-adr-mapping/lab-observation',
    SERVER: 'SMART_ADR_BACKEND',
  },
  GET_MEDICATION_NAME_AND_PRODUCT_ID: {
    USECASE: 'GET_MEDICATION_NAME_AND_PRODUCT_ID',
    URL: '/gsdd/get-medication-name-product-id',
    SERVER: 'SMART_ADR_BACKEND',
  },
  GET_MEDICATION_FROM_EPIC: {
    USECASE: 'GET_MEDICATION_FROM_EPIC',
    URL: '/MedicationRequest',
    SERVER: 'EPIC',
  },
  GET_LAB_OBSERVATION_FROM_EPIC: {
    USECASE: 'GET_LAB_OBSERVATION_FROM_EPIC',
    URL: '/Observation',
    SERVER: 'EPIC',
  },
  GET_MEDICATION_DETAILS_FROM_EPIC: {
    USECASE: 'GET_MEDICATION_DETAILS_FROM_EPIC',
    URL: '/Medication/$Medication_Id',
    SERVER: 'EPIC',
  },
} as {
  [key: string]: {
    USECASE: string;
    URL: string;
    SERVER: string;
  };
};

export default UrlConstants;
