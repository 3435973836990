import styled from 'styled-components';

export const LayoutBG = styled.div`
  background-color: ${(props) => props.theme.dashboardBG};
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
`;

export const UserProfileContainer = styled.div`
  // min-width: 320px;
  width:200px;
  padding: 1rem;
  // @media (max-width: 1025px) {
  //   min-width: 280px;
  //   width: 20%;
  // }
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.dashboardBGMain};
  width: 100%;
  min-height: 100vh;
  height: 100%;
`;
