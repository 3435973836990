import { HeadingProps } from '../../types/styles.types';
import React from 'react';
import styled from 'styled-components';
import theme from '../../global/theme/theme';

const H1Element: React.FC = styled.h1<{
  color: string;
  padding: string;
  margin: string;
}>`
  color: ${(props) => props.color};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
`;

const H2Element: React.FC = styled.h2<{
  color: string;
  padding: string;
  margin: string;
}>`
  color: ${(props) => props.color};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
`;

const H3Element: React.FC = styled.h3<{
  color: string;
  padding: string;
  margin: string;
}>`
  color: ${(props) => props.color};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
`;

const H4Element: React.FC = styled.h4<{
  color: string;
  padding: string;
  margin: string;
}>`
  color: ${(props) => props.color};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
`;

const Heading: React.FC<HeadingProps> = ({
  children,
  color = theme.text.primary,
  level = 2,
  padding = '0',
  margin = '0',
}) => {
  let HeadingElement: React.ElementType;
  switch (level) {
    case 1:
      HeadingElement = H1Element;
      break;
    case 2:
      HeadingElement = H2Element;
      break;
    case 3:
      HeadingElement = H3Element;
      break;
    case 4:
      HeadingElement = H4Element;
      break;
    default:
      HeadingElement = H2Element;
  }
  return (
    <HeadingElement color={color} padding={padding} margin={margin}>
      {children}
    </HeadingElement>
  );
};

export default Heading;
