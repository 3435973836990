import styled from 'styled-components';

export const CardContainer = styled.div`
  background-color: ${(props) => props.theme.white};
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-left: 5px solid ${(props) => (props.color ? props.color : '#000')};
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
  min-width: 320px;
   width: 590px;
  break-inside: avoid;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 1rem;
  }

  ::-webkit-scrollbar-thumb {
    height: 1rem;
    border-radius: 0.5rem;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: #eeeeee;

`;

export const CardPill = styled.div`
  background-color: ${(props) =>
    props.color ? props.color + '2F' : '#00000033'};
  height: 40px;
  border-radius: 0px 20px 20px 0px;
  display: flex;
  align-items: center;
  padding: 0px 1.5rem;
  color: ${(props) => (props.color ? props.color : '#000')};
`;
