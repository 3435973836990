import styled, { CSSObject } from 'styled-components';

export const MedElementContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem 0rem 1rem;
  width: 712px;
  margin-bottom: 5px;
`;

export const LastActionRow = styled.div`
  display: flex;
  margin-top: 1rem;
`;

export const SearchTerm = styled.div`
  width: 100%;
  border: 1px solid #b5297d; /* Set border color to #b5297d */
  height: 40px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: grey; /* Set text color to grey */
  padding-left: 25px;
  font-family: Arial, FontAwesome;
`;
export const SearchResultText = styled.div`
  color: #b5297d;
  padding-top: 10px;
  padding-bottom: 10px;
`;
export const MedOverlay = styled.div<{ highlight: string }>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: ${(props) => getBackgroundColor(props.highlight)};
  z-index: 10;
  cursor: pointer;
`;
export const AdrFrequencyAlignment = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
`;

export const AdrName = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 24px;
  padding-bottom: 13px;
  z-index: 100;
`;
export const AdrLabDetails = styled.div<{ highlight: string }>`
  padding: 10px;
  min-width: 200px;
  height: 100%;
  background: ${(props) => adrDetailsBackgroundColor(props.highlight)};
  margin-right: 10px;
  border: ${(props) => adrDetailsBorderColor(props.highlight)};
  border-radius: 6px;
`;
export const Text = styled.div`
  color: black;
`;

const getBackgroundColor = (
  highlight: string
): CSSObject['backgroundColor'] => {
  switch (highlight) {
    case 'red':
      return 'rgba(255, 217, 217, 1)';
    case 'yellow':
      return 'rgba(253, 255, 217, 1)';
    case 'green':
      return 'rgba(215, 247, 221, 1)';
    default:
      return 'transparent';
  }
};

const adrDetailsBackgroundColor = (
  highlight: string
): CSSObject['backgroundColor'] => {
  switch (highlight) {
    case 'red':
      return 'rgba(255, 237, 237, 1)';
    case 'yellow':
      return 'rgba(254, 255, 237, 1)';
    case 'green':
      return 'rgba(219, 255, 223, 1)';
    default:
      return 'transparent';
  }
};

const adrDetailsBorderColor = (
  highlight: string
): CSSObject['backgroundColor'] => {
  switch (highlight) {
    case 'red':
      return '2px solid rgba(181, 48, 48, 1)';
    case 'yellow':
      return '2px solid rgba(174, 181, 48, 1)';
    case 'green':
      return '2px solid rgba(48, 181, 72, 1)';
    default:
      return 'transparent';
  }
};

export const ApplyButton = styled.div`
  background: #fff;
  text-align: center;
  border: 1px solid #b5297d;
  border-radius: 10px;
  width: 100px;
  height: 34px;
  padding-top: 4px;
  align-self: center;
  margin-left: 20px;
`;

export const ColumnContainer = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  cursor: pointer;
  z-index: 50;
  padding-bottom: 10px;

  ::-webkit-scrollbar {
    width: 1rem;
  }

  ::-webkit-scrollbar-thumb {
    height: 1rem;
    border-radius: 0.5rem;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: #eeeeee;
  }

  -ms-overflow-style: none;
`;

export const DescriptionText = styled.div`
  padding: 110px;
  color: mediumvioletred;
  text-align: center;
  width: 690px;
`;

export const ApplyFilterButton = styled.div`
  background: #fff;
  text-align: center;
  border: 1px solid #b5297d;
  border-radius: 4px;
  width: 90px;
  height: 40px;
  padding-top: 7px;
  align-self: center;
  color:#B5307D;
  &:hover {
    color: white;
    background: #B5307D;
  }
`;
